import React from 'react';
import Helmet from 'react-helmet';
import { Provider } from 'react-redux';

import store from '../../store';
import EditStatusOverlay from './components/EditStatusOverlay';
import ManageTravelsAdmin from './components/ManageTravelsAdmin';

export default function TravelsAdmin() {
  return (
    <Provider store={store}>
      <Helmet>
        <title>Gerenciar Viagens - AllianceSystem</title>
      </Helmet>
      <ManageTravelsAdmin />
      <EditStatusOverlay />
    </Provider>
  );
}
