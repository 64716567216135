import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import config from '../services/config.json';

import api from '../services/MainAPI';

import LogInWithDiscord from './images/loginwithdiscord.png';

import './style/login.css';

export default function Login() {
  const navigate = useNavigate();

  async function checkLogin() {
    const userToken = localStorage.getItem('@alliancesystem/token');
    const res = await api.get('/auth/primaryauth', {}, { headers: { Authorization: userToken } });
    if (res.data.error) {
      localStorage.removeItem('@alliancesystem/token');
    } else {
      navigate.push('/alliancesystem');
    }
  }

  useEffect(() => {
    checkLogin();
  }, []);

  return (
    <>
      <Helmet>
        <title>Login - AllianceSystem</title>
      </Helmet>
      <div className="login">
        <div className="loginbox">
          <h1>Login - AllianceSystem</h1>
          <p>
            Para entrar no AllianceSystem, prossiga logando no Discord:
          </p>
          <a href={`${config.backendUrl}/auth`}><img src={LogInWithDiscord} alt="Logar no Discord" /></a>
        </div>
      </div>
    </>
  );
}
