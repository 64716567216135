/* eslint-disable max-len */
import React from 'react';
import styled from 'styled-components';

export default function LoginFailedTemp() {
  const FailedDiv = styled.div`
    font-family: 'Roboto', sans-serif;
    vertical-align: middle;
    margin-top: 20vw;
  `;

  const TitlePage = styled.h1`
    font-size: 1.8em;
    text-align: center;
    color: #000000;
    `;
  const ReturnLink = styled.a`
    font-size: 1.4em;
    text-decoration: none;
    color: blue;
    `;

  return (
    <FailedDiv style={{ textAlign: 'center' }}>
      <TitlePage>Desculpe, mas o AllianceSystem ainda não está disponível para você. Aguarde enquanto novidades são incorporadas ao sistema.</TitlePage>
      <ReturnLink href="/">Voltar ao Site da Alliance</ReturnLink>
    </FailedDiv>
  );
}
