/* eslint-disable default-param-last */
const INITIAL_STATE = {
  isTravel: false,
  isEditStatusOpen: false,
  updateTravels: true,
};

export default function travels(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'UPDATE_TRAVEL':
      return { ...state, isTravel: true, data: action.data };
    case 'OPEN_UPDATE_STATUS_TAB':
      return { ...state, isEditStatusOpen: true };
    case 'CLOSE_UPDATE_STATUS_TAB':
      return { ...state, isEditStatusOpen: false };
    case 'UPDATE_TRAVELS_CACHE':
      return { ...state, updateTravels: true };
    case 'DISABLE_UPDATE_TRAVELS_VAR':
      return { ...state, updateTravels: false };
    default:
      return state;
  }
}
