/* eslint-disable react/self-closing-comp */
/* eslint-disable react/react-in-jsx-scope */
import { useNavigate } from 'react-router-dom';

export default function Logoff() {
  const navigate = useNavigate();

  localStorage.removeItem('@alliancesystem/token');
  navigate('/');

  return (
    <div>
    </div>
  );
}
