/* eslint-disable react/jsx-no-script-url */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/self-closing-comp */
import React, { useEffect, useState } from 'react';
import Collapsible from 'react-collapsible';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import api from '../../../services/MainAPI';

import '../css/listDriverInterface.css';

export default function ListDriversInferface() {
  const notification = useSelector((state) => state.driverGap.reload);

  const [driverData, setDriverData] = useState({ games: [], skills: [] });
  const { id } = useParams();
  const dispatch = useDispatch();

  async function getDriverData() {
    const userToken = localStorage.getItem('@alliancesystem/token');
    const res = await api.get(`/gap/listdriver/${id}`, {}, { headers: { Authorization: userToken } });
    setDriverData(res.data);
  }

  function alterDriverData() {
    dispatch({ type: 'OPEN_DRIVER_DATA' });
  }

  function alterGamesData() {
    dispatch({ type: 'OPEN_GAMES_DATA' });
  }

  function alterSkillsData() {
    dispatch({ type: 'OPEN_SKILLS_DATA' });
  }

  function alterLoa() {
    dispatch({ type: 'OPEN_ALTER_LOA' });
  }

  function fireDriver() {
    dispatch({ type: 'OPEN_FIRE_DRIVER' });
  }

  useEffect(() => {
    if (notification) {
      dispatch({ type: 'UPDATED' });
      getDriverData();
    }
  }, [notification]);

  return (
    <main className="defaultMargin listdriver">
      <h1 className="title">Dados do Motorista:</h1>
      <div className="left">
        <ul className="maindata">
          <li>
            <span className="bold">Nome Real:</span>
            {' '}
            {driverData.realname}
          </li>
          <li>
            <span className="bold">Apelido:</span>
            {' '}
            {driverData.nickname}
          </li>
          <li>
            <span className="bold">Apelido no TruckersMP:</span>
            {' '}
            {driverData.truckersmpnickname}
          </li>
          <li>
            <span className="bold">Cargo:</span>
            {' '}
            {driverData.rolename}
          </li>
          <li>
            <span className="bold">Aniversário:</span>
            {' '}
            {driverData.birthday}
          </li>
          <li>
            <span className="bold">País:</span>
            {' '}
            {driverData.country}
          </li>
          <li>
            <span className="bold">Perfil no TruckersMP:</span>
            {' '}
            <a href={`https://truckersmp.com/user/${driverData.truckersmpid}`} rel="noreferrer" target="_blank">Clique Aqui</a>
          </li>
          <li>
            <span className="bold">Perfil no Trucksbook:</span>
            {' '}
            <a href={`https://trucksbook.eu/profile/${driverData.trucksbookid}`} rel="noreferrer" target="_blank">Clique Aqui</a>
          </li>
          <li>
            <span className="bold">Perfil na Steam:</span>
            {' '}
            <a href={`https://steamcommunity.com/profiles/${driverData.steamid}`} rel="noreferrer" target="_blank">Clique Aqui</a>
          </li>
          <li>
            <span className="bold">VTC no TruckersMP:</span>
            {' '}
            {driverData.vtctruckersmp}
          </li>
          <li>
            <span className="bold">Necessidade Especial:</span>
            {' '}
            {driverData.specialNeed}
          </li>
          <li>
            <span className="bold">Recrutado por:</span>
            {' '}
            {driverData.recruternickname}
          </li>
          {driverData.loa && <li><span className="bold">Motorista em LOA: </span>Sim</li>}
          {!driverData.loa && <li><span className="bold">Motorista em LOA: </span>Não</li>}
          {driverData.loa && <li><span className="bold">Razão do LOA: </span>{driverData.loaReason}</li>}
          <br />
          <li>
            <span className="bold">Observações: </span>
            <br />
            <textarea className="observation" value={driverData.obs} disabled></textarea>
          </li>
        </ul>

      </div>
      <div className="right">
        <div className="buttons">
          <div>
            <a href="javascript:void(0)" onClick={alterDriverData}>Alterar os Dados do Motorista</a>
            <a href="javascript:void(0)" onClick={alterGamesData}>Alterar os Jogos do Motorista</a>
          </div>
          <div>
            <a href="javascript:void(0)" onClick={alterSkillsData}>Alterar as Habilidades do Motorista</a>
            <a href="javascript:void(0)" onClick={alterLoa} className="warning">Alterar o LOA do Motorista</a>
          </div>
          <div>
            <a href="javascript:void(0)" onClick={fireDriver} className="red">Demitir Motorista</a>
          </div>
        </div>
        <Collapsible trigger="🚚 Jogos e DLCs" className="gamescollapsible">
          <div className="gamesdriver">
            <div className="left">
              <ul>
                {driverData.games.map((game) => {
                  if (game.type === 'ETS') {
                    if (game.value === 1) {
                      return <li className="green">{game.name}</li>;
                    }
                    return <li className="red">{game.name}</li>;
                  }
                  return null;
                })}
              </ul>
            </div>
            <div className="right" style={{ marginTop: '-20px' }}>
              <ul>
                {driverData.games.map((game) => {
                  if (game.type === 'ATS') {
                    if (game.value === 1) {
                      return <li className="green">{game.name}</li>;
                    }
                    return <li className="red">{game.name}</li>;
                  }
                  return null;
                })}
              </ul>
            </div>
          </div>
        </Collapsible>
        <Collapsible trigger="🔧 Habilidades" className="gamescollapsible" style={{ marginTop: '-20px' }}>
          <div className="gamesdriver">
            {driverData.skills.map((skill) => {
              if (skill.value === 1) return <li className="green">{skill.description}</li>;
              return <li className="red">{skill.description}</li>;
            })}
          </div>
        </Collapsible>
      </div>
    </main>
  );
}
