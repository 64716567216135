import React, { useEffect, useState } from 'react';

import api from '../../../services/MainAPI';

export default function ListUserTravels() {
  const [travels, setTravels] = useState([]);

  useEffect(async () => {
    const userToken = localStorage.getItem('@alliancesystem/token');

    const res = await api.get('/travels/usertraveldata', {}, { headers: { Authorization: userToken } });
    setTravels(res.data.data);
  }, []);

  return (
    <main className="defaultMargin listdrivers" style={{ marginTop: '10px' }}>
      <table>
        <tr className="titles">
          <th>ID</th>
          <th>Data</th>
          <th>Cidade de Partida</th>
          <th>Cidade de Destino</th>
          <th>Carga</th>
          <th>Peso da Carga</th>
          <th>Distancia Percorrida</th>
          <th>Distancia Aceita</th>
          <th>Caminhão Utilizado</th>
          <th>Status</th>
        </tr>
        {travels.map((data) => (
          <tr>
            <td>{data.id}</td>
            <td>{data.date}</td>
            {!data.isCitySource && <td style={{ fontStyle: 'italic' }}>{data.citySource}</td>}
            {data.isCitySource && <td>{data.citySource}</td>}
            {!data.isCityDestination && <td style={{ fontStyle: 'italic' }}>{data.cityDestination}</td>}
            {data.isCityDestination && <td>{data.cityDestination}</td>}
            {!data.isCargoName && <td style={{ fontStyle: 'italic' }}>{data.cargo}</td>}
            {data.isCargoName && <td>{data.cargo}</td>}
            <td>{`${data.weight} kg`}</td>
            <td>{`${data.drivenDistance} km`}</td>
            <td>{`${data.acceptedDistance} km`}</td>
            <td>{data.truck}</td>
            {data.status === 'PENDING' && <td>Pendente</td>}
            {data.status === 'APPROVED' && <td>Aprovado</td>}
            {data.status === 'REPROVED' && <td>Reprovado</td>}
            {data.status === 'IN EVALUATION' && <td>Em Avaliação</td>}
          </tr>
        ))}
      </table>
    </main>
  );
}
