/* eslint-disable react/jsx-no-script-url */
/* eslint-disable no-else-return */
/* eslint-disable react/button-has-type */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Switch from 'react-switch';
import { useNavigate, useParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';

import api from '../../../services/MainAPI';

import '../css/listDriverNotification.css';

export default function ListDriverNotification() {
  const navigate = useNavigate();
  const notification = useSelector((state) => state.driverGap);
  const dispatch = useDispatch();

  const { id } = useParams();

  const [data, setData] = useState({ games: [], skills: [] });
  const [roles, setRoles] = useState({});

  const [isRegistering, setIsRegistering] = useState(false);
  const [message, setMessage] = useState(<span></span>);

  const [permanentBanned, setPermanentBanned] = useState(false);
  const [isFired, setIsFired] = useState(false);
  const [fireReason, setFireReason] = useState('');

  function closeDriverData() {
    dispatch({ type: 'CLOSE' });
    setMessage(<span></span>);
  }
  function closeFireDriverTab() {
    if (isFired) {
      navigate('/alliancesystem/gap/drivers');
    } else {
      dispatch({ type: 'CLOSE' });
      setMessage(<span></span>);
    }
  }

  async function getRoles() {
    const userToken = localStorage.getItem('@alliancesystem/token');
    const responseRoles = await api.get('/interface/provider/roles', {}, { headers: { Authorization: userToken } });
    setRoles(responseRoles.data);
    const res = await api.get(`/gap/listdriver/${id}`, {}, { headers: { Authorization: userToken } });
    console.log(res.data);
    setData(res.data);
  }

  async function alterData(e) {
    e.preventDefault();
    const userToken = localStorage.getItem('@alliancesystem/token');
    setIsRegistering(true);
    const response = await api.post('/gap/changememberdata', {
      member_id: id,
      nickname: data.nickname,
      role: data.role,
      staffComment: data.obs,
    }, { headers: { Authorization: userToken } });
    dispatch({ type: 'RELOAD' });
    setIsRegistering(false);
    setMessage(<span className="green">Os Dados foram Atualizados com Sucesso!</span>);
  }

  async function alterGames(e) {
    e.preventDefault();
    const userToken = localStorage.getItem('@alliancesystem/token');
    setIsRegistering(true);
    const response = await api.post('/gap/changemembergames', {
      member_id: id,
      games: data.games,
    }, { headers: { Authorization: userToken } });
    dispatch({ type: 'RELOAD' });
    setIsRegistering(false);
    setMessage(<span className="green">Os Dados foram Atualizados com Sucesso!</span>);
  }

  async function alterSkills(e) {
    e.preventDefault();
    const userToken = localStorage.getItem('@alliancesystem/token');
    setIsRegistering(true);
    const response = await api.post('/gap/changememberskills', {
      member_id: id,
      skills: data.skills,
    }, { headers: { Authorization: userToken } });
    dispatch({ type: 'RELOAD' });
    setIsRegistering(false);
    setMessage(<span className="green">Os Dados foram Atualizados com Sucesso!</span>);
  }

  async function alterLoa(e) {
    e.preventDefault();
    const userToken = localStorage.getItem('@alliancesystem/token');
    setIsRegistering(true);
    const response = await api.put('/gap/setloa', {
      member_id: id,
      loa: data.loa,
      loaReason: data.loaReason,
    }, { headers: { Authorization: userToken } });
    dispatch({ type: 'RELOAD' });
    setIsRegistering(false);
    setMessage(<span className="green">Os Dados foram Atualizados com Sucesso!</span>);
  }

  async function fireDriver(e) {
    e.preventDefault();
    const userToken = localStorage.getItem('@alliancesystem/token');
    setIsRegistering(true);
    setIsFired(true);
    const response = await api.post('/gap/firedrive', {
      member_id: id,
      reason: fireReason,
      isBanned: permanentBanned,
    }, { headers: { Authorization: userToken } });
    setIsRegistering(false);
    setMessage(<span className="green">O Motorista foi Demitido com Sucesso!</span>);
  }

  useEffect(() => {
    getRoles();
  }, []);

  if (notification.driverData) {
    return (
      <div className="registerNotification driverNotification">
        <div className="containner">
          <a href="javascript:void(0)" onClick={closeDriverData}><i className="fas fa-times" /></a>
          <h1>Alterar os Dados do Motorista</h1>
          <form className="alterdata" onSubmit={alterData}>
            <button className="button" disabled={isRegistering}>
              <ClipLoader css={{ position: 'absolute', marginLeft: '-23px', marginTop: '2px' }} color="#ffffff" loading={isRegistering} size={12} />
              {' '}
              Enviar
            </button>
            <p className="message">{message}</p>
            <div className="field">
              <label htmlFor="nickname">Apelido:</label>
              <input
                type="text"
                id="nickname"
                name="nickname"
                required
                value={data.nickname}
                onChange={(e) => setData({ ...data, nickname: e.target.value })}
              />
            </div>
            <div className="field">
              <label>Cargo:</label>
              <select name="divisions" value={data.role} onChange={(e) => setData({ ...data, role: e.target.value })}>
                {roles.map((dataroles) => (
                  <option value={dataroles.role_id}>{dataroles.name}</option>
                ))}
              </select>
            </div>
            <div className="field">
              <label>Observações:</label>
              <br />
              <textarea
                id="obs"
                name="obs"
                value={data.obs}
                onChange={(e) => setData({ ...data, obs: e.target.value })}
              >
              </textarea>
            </div>
          </form>
        </div>
      </div>
    );
  }
  if (notification.gamesData) {
    return (
      <div className="registerNotification driverNotification">
        <div className="containner">
          <a href="javascript:void(0)" onClick={closeDriverData}><i className="fas fa-times" /></a>
          <h1>Alterar os Jogos do Motorista</h1>
          <form className="alterdata altergames" onSubmit={alterGames}>
            <button className="button" disabled={isRegistering}>
              <ClipLoader css={{ position: 'absolute', marginLeft: '-23px', marginTop: '2px' }} color="#ffffff" loading={isRegistering} size={12} />
              {' '}
              Enviar
            </button>
            <p className="message">{message}</p>
            <div className="left">
              {data.games.map((game) => {
                if (game.type === 'ETS') {
                  return (
                    <div className="switchld">
                      <label>
                        <Switch
                          height={20}
                          width={40}
                          checked={game.value}
                          onChange={(e) => setData({
                            ...data,
                            games: data.games.map((x, i) => (x.game_id === game.game_id ? {
                              game_id: x.game_id,
                              name: x.name,
                              type: x.type,
                              value: (e ? 1 : 0),
                            } : x)),
                          })}
                        />
                        <span>{game.name}</span>
                      </label>
                    </div>
                  );
                }
                return null;
              })}
            </div>
            <div className="right">
              {data.games.map((game) => {
                if (game.type === 'ATS') {
                  return (
                    <div className="switchld">
                      <label>
                        <Switch
                          height={20}
                          width={40}
                          checked={game.value}
                          onChange={(e) => setData({
                            ...data,
                            games: data.games.map((x, i) => (x.game_id === game.game_id ? {
                              game_id: x.game_id,
                              name: x.name,
                              type: x.type,
                              value: (e ? 1 : 0),
                            } : x)),
                          })}
                        />
                        <span>{game.name}</span>
                      </label>
                    </div>
                  );
                }
                return null;
              })}
            </div>
          </form>
        </div>
      </div>
    );
  }
  if (notification.skillsData) {
    return (
      <div className="registerNotification driverNotification">
        <div className="containner">
          <a href="javascript:void(0)" onClick={closeDriverData}><i className="fas fa-times" /></a>
          <h1>Alterar as Habilidades do Motorista</h1>
          <form className="alterdata altergames" onSubmit={alterSkills} style={{ marginLeft: '10px' }}>
            <button className="button" disabled={isRegistering}>
              <ClipLoader css={{ position: 'absolute', marginLeft: '-23px', marginTop: '2px' }} color="#ffffff" loading={isRegistering} size={12} />
              {' '}
              Enviar
            </button>
            <p className="message">{message}</p>
            {data.skills.map((skill) => (
              <div className="switchld">
                <label>
                  <Switch
                    height={20}
                    width={40}
                    checked={skill.value}
                    onChange={(e) => setData({
                      ...data,
                      skills: data.skills.map((x, i) => ((x.skill_id === skill.skill_id) ? {
                        skill_id: x.skill_id,
                        description: x.description,
                        value: (e ? 1 : 0),
                      } : x)),
                    })}
                  />
                  <span>{skill.description}</span>
                </label>
              </div>
            ))}
          </form>
        </div>
      </div>
    );
  }
  if (notification.alterloa) {
    return (
      <div className="registerNotification driverNotification">
        <div className="containner">
          <a href="javascript:void(0)" onClick={closeDriverData}><i className="fas fa-times" /></a>
          <h1>Alterar LOA do Motorista</h1>
          <form className="alterdata altergames" onSubmit={alterLoa} style={{ marginLeft: '10px' }}>
            <button className="button" disabled={isRegistering}>
              <ClipLoader css={{ position: 'absolute', marginLeft: '-23px', marginTop: '2px' }} color="#ffffff" loading={isRegistering} size={12} />
              {' '}
              Enviar
            </button>
            <p className="message">{message}</p>
            <div className="switchld">
              <label>
                <Switch height={20} width={40} checked={data.loa} onChange={(e) => setData({ ...data, loa: e ? 1 : 0 })} />
                <span>Habilitar LOA do Motorista.</span>
              </label>
            </div>
            <br />
            <div className="field">
              <label>Razão do LOA e duração:</label>
              <textarea
                id="loareason"
                name="loareason"
                value={data.loaReason}
                onChange={(e) => setData({ ...data, loaReason: e.target.value })}
              >
              </textarea>
            </div>
          </form>
        </div>
      </div>
    );
  }
  if (notification.firedriver) {
    return (
      <div className="registerNotification driverNotification">
        <div className="containner">
          <a href="javascript:void(0)" onClick={closeFireDriverTab}><i className="fas fa-times" /></a>
          <h1>Demitir Motorista</h1>
          <form className="alterdata altergames" onSubmit={fireDriver} style={{ marginLeft: '10px' }}>
            <button className="button" disabled={isRegistering}>
              <ClipLoader css={{ position: 'absolute', marginLeft: '-23px', marginTop: '2px' }} color="#ffffff" loading={isRegistering} size={12} />
              {' '}
              Enviar
            </button>
            <p className="message">{message}</p>
            <div className="switchld">
              <label>
                <Switch height={20} width={40} checked={permanentBanned} onChange={(e) => setPermanentBanned(e)} />
                <span>Banido Permanentemente</span>
              </label>
            </div>
            <br />
            <br />
            <div className="field">
              <label>Razão da Demissão:</label>
              <br />
              <textarea
                id="obs"
                name="obs"
                value={fireReason}
                onChange={(e) => setFireReason(e.target.value)}
              >
              </textarea>
            </div>
          </form>
        </div>
      </div>
    );
  }
  return (null);
}
