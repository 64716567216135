/* eslint-disable react/jsx-no-target-blank */
import React from 'react';

import Logo from '../images/logo.png';

import '../style/footer.css';

function Footer() {
  return (
    <footer className="footer" style={{ marginTop: '-4px' }}>
      <div className="footer-margin">
        <div className="logo-footer">
          <a href="/"><img alt="Alliance Logo" style={{ height: '130px' }} className="logo-img" src={Logo} /></a>
        </div>
        <div className="footer-others">
          <div className="social-medias-footer">
            <ul>
              <li><a target="_black" aria-label="Discord" href="https://discord.gg/Zzq7Shs"><i className="fab fa-discord social-media" /></a></li>
              <li><a target="_black" aria-label="Instagram" href="https://www.instagram.com/alliancevirtual.ofc/"><i className="fab fa-instagram social-media" /></a></li>
              <li><a target="_black" aria-label="Facebook" href="https://www.facebook.com/alliancevirtual.ofc/"><i className="fab fa-facebook social-media" /></a></li>
              <li><a target="_black" aria-label="Youtube" href="https://www.youtube.com/channel/UCr3wgPmKEsoJSsoQS6cEqvQ"><i className="fab fa-youtube social-media" /></a></li>
            </ul>
          </div>
          <div>
            <p>
              <span style={{ color: '#fff' }}>Site desenvolvido por</span>
              {' '}
              <a className="footer-a" target="_blank" href="https://github.com/RubensOriginal">RubensOriginal</a>
              <br />
              <a className="footer-a" target="_blank" href="https://alliancevirtual.com.br:3002/files/documents/termsofservice.pdf">Termos de Uso</a>
              {' '}
              <span style={{ color: '#fff' }}>|</span>
              {' '}
              <a className="footer-a" target="_blank" href="https://alliancevirtual.com.br:3002/files/documents/privacypolicy.pdf">Politicas de Privacidade</a>
              {' '}
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
