/* eslint-disable max-len */
import React from 'react';

import '../style/apply3.css';

function Apply3() {
  return (
    <div style={{ backgroundColor: '#5c5c5c' }}>
      <div className="middle-3-apply">
        <p style={{ color: 'white' }}>Para entrar na empresa, você deve concordar com os Termos de Uso e Política de Privacidade, além de concordar com o Regulamento da Empresa e quer cumprir os requisitos.</p>
        <p>
          <a target="_blank" rel="noreferrer" href="https://alliancevirtual.com.br:3002/files/documents/termsofservice.pdf">Termos de Uso</a>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <a target="_blank" rel="noreferrer" href="https://alliancevirtual.com.br:3002/files/documents/privacypolicy.pdf">Política de Privacidade</a>
        </p>
      </div>
    </div>
  );
}

export default Apply3;
