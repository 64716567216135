import React from 'react';

import Background from '../images/staff-background.jpg';

import '../style/apply1.css';

function StaffPresentation() {
  return (
    <div className="middle-1-apply">
      <div className="container-all">
        <img
          alt=""
          style={{
            width: '100%', height: '90vh', position: 'relative', top: '-6px', objectFit: 'cover',
          }}
          className="background-1"
          src={Background}
        />
        <div className="centered">
          <h2>Nossa Equipe</h2>
        </div>
      </div>
    </div>
  );
}

export default StaffPresentation;
