import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Slide } from '@mui/material';
import Footer from './components/Footer';
import Header from './components/Header';
import StaffList from './components/StaffList';
import StaffPresentation from './components/StaffPresentation';
import LoadingScreen from './LoadingScreen';

function Staff() {
  const state = {
    activeTab: {
      home: '',
      aboutUs: '',
      events: '',
      staff: 'active',
      apply: '',
    },
    linkWebsite: '/staff',
  };

  const [isLoading, setIsLoading] = React.useState(true);

  useEffect(() => {
    const onPageLoad = () => {
      setTimeout(() => { setIsLoading(false); }, 1000);
    };

    while (document.readyState === 'loading') {
      setTimeout(() => { }, 100);
    }

    onPageLoad();
  }, []);

  if (isLoading) {
    return (
      <Slide direction="up" in={isLoading} enter={false} unmountOnExit>
        <div>
          <LoadingScreen />
        </div>
      </Slide>
    );
  }

  return (
    <>
      <Helmet>
        <title>Nossa Equipe - Alliance Virtual</title>
      </Helmet>
      <Header activeTab={state.activeTab} linkWebsite={state.linkWebsite} />
      <StaffPresentation />
      <StaffList />
      <Footer />
    </>
  );
}

export default Staff;
