/* eslint-disable react/jsx-no-script-url */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { useDispatch } from 'react-redux';

import '../css/popup.css';

export default function StatusPopup(props) {
  const dispatch = useDispatch();

  const {
    isShowing, text, type, height, dispatchName,
  } = props;

  function closePopup() {
    dispatch({ type: dispatchName });
  }

  if (isShowing) {
    if (type === 'SUCCESS') {
      return (
        <div id="popup" className="success" style={{ top: height }}>
          <a href="javascript:void(0)" onClick={() => { closePopup(); }}><i className="fas fa-times" /></a>
          <p>{text}</p>
        </div>
      );
    }
    if (type === 'FAILED') {
      return (
        <div id="popup" className="failed" style={{ top: height }}>
          <a href="javascript:void(0)" onClick={() => { closePopup(); }}><i className="fas fa-times" /></a>
          <p>{text}</p>
        </div>
      );
    }
    if (type === 'WARNING') {
      return (
        <div id="popup" className="warning" style={{ top: height }}>
          <a href="javascript:void(0)" onClick={() => { closePopup(); }}><i className="fas fa-times" /></a>
          <p>{text}</p>
        </div>
      );
    }
  }
  return null;
}
