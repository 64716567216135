/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import api from '../../../services/MainAPI';

import '../css/travels.css';

import Header from '../../components/UI_Components/Header';
import LateralNavigation from '../../components/UI_Components/LateralNavigation';
import SidebarAdminTravels from './SidebarAdminTravels';

let checkLogin = false;

export default function ManageTravelsAdmin() {
  const travelSelector = useSelector((state) => state.travels);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [primaryAuth, setPrimaryAuth] = useState([]);
  const [data, setData] = useState([]);
  const [travel, setTravel] = useState({});

  async function checkLoginFunction() {
    if (!checkLogin) {
      const userToken = localStorage.getItem('@alliancesystem/token');
      const res = await api.get('/auth/primaryauth', {}, { headers: { Authorization: userToken } });
      if (res.data.error) {
        navigate('/alliancesystem/login');
      } else {
        setPrimaryAuth(res.data);
        checkLogin = true;
      }
    }
  }

  async function getTravelData(id) {
    const userToken = localStorage.getItem('@alliancesystem/token');
    const apiRes = await api.get(`/travels/admin/travel/${id}`, {}, { headers: { Authorization: userToken } });
    setTravel(apiRes.data.data);
    dispatch({ type: 'UPDATE_TRAVEL', data: apiRes.data.data });
  }

  async function getTravels() {
    if (travelSelector.updateTravels) {
      const userToken = localStorage.getItem('@alliancesystem/token');
      const apiRes = await api.get('/travels/admin/travels', {}, { headers: { Authorization: userToken } });
      setData(apiRes.data.data);
      if (travelSelector.isTravel) {
        getTravelData(travelSelector.data.id);
      }
      dispatch({ type: 'DISABLE_UPDATE_TRAVELS_VAR' });
    }
  }

  checkLoginFunction();

  useEffect(async () => {
    getTravels();
  }, [travelSelector.updateTravels]);

  return (
    <>
      <Header primaryAuthData={primaryAuth} sectorColor="blueHeader" />
      <LateralNavigation primaryAuthData={primaryAuth} navigationSector="TRAVELS" defaultTab={{ value: true, name: 'Voltar às Viagens', url: '/travels' }} />
      <main className="defaultMargin" id="travelsAdmin">
        <div className="travelsLeft">
          <h1>Gerenciamento de Viagens</h1>
          <table>
            <thead>
              <th>ID</th>
              <th>Motorista</th>
              <th>Origem</th>
              <th>Destino</th>
              <th>Carga</th>
              <th>Status</th>
              <th>Mais Informações</th>
            </thead>
            <tbody>
              {data.map((_data) => (
                <tr className={_data.status}>
                  <td>{_data.id}</td>
                  <td>{_data.username}</td>
                  {!_data.isCitySource && <td style={{ fontStyle: 'italic' }}>{_data.citySource}</td>}
                  {_data.isCitySource && <td>{_data.citySource}</td>}
                  {!_data.isCityDestination && <td style={{ fontStyle: 'italic' }}>{_data.cityDestination}</td>}
                  {_data.isCityDestination && <td>{_data.cityDestination}</td>}
                  {!_data.isCargoName && <td style={{ fontStyle: 'italic' }}>{_data.cargo}</td>}
                  {_data.isCargoName && <td>{_data.cargo}</td>}
                  {_data.status === 'PENDING' && <td>Pendente</td>}
                  {_data.status === 'APPROVED' && <td>Aprovado</td>}
                  {_data.status === 'REPROVED' && <td>Reprovado</td>}
                  {_data.status === 'IN EVALUATION' && <td>Em Avaliação</td>}
                  <td><a href="#" onClick={() => { getTravelData(_data.id); }}>Clique Aqui</a></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="travelsRight">
          <SidebarAdminTravels />
        </div>
      </main>
    </>
  );
}
