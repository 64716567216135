import React from 'react';
import { Helmet } from 'react-helmet';

import Header from './components/Header';
import EventBody from './components/EventBody';
import Footer from './components/Footer';

function Events() {
  const state = {
    activeTab: {
      home: '',
      aboutUs: '',
      events: 'active',
      staff: '',
      apply: '',
    },
    linkWebsite: '/',
  };
  return (
    <>
      <Helmet>
        <title>Início - Alliance Virtual</title>
      </Helmet>
      <Header activeTab={state.activeTab} linkWebsite={state.linkWebsite} />
      <EventBody />
      <Footer />
    </>
  );
}

export default Events;
