/* eslint-disable react/jsx-no-script-url */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ClipLoader } from 'react-spinners';

import api from '../../../services/MainAPI';

import '../css/overlay.css';

const validationValues = [
  { name: 'Pendente', value: 'PENDING', isDisabled: true },
  { name: 'Aprovado', value: 'APPROVED', isDisabled: false },
  { name: 'Reprovado', value: 'REPROVED', isDisabled: false },
  { name: 'Em Avaliação', value: 'IN EVALUATION', isDisabled: false },
];

export default function EditStatusOverlay() {
  const travelSelector = useSelector((state) => state.travels);

  const dispatch = useDispatch();

  const [travelStatus, setTravelStatus] = useState('');
  const [travelObservation, setTravelObservation] = useState('');

  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (travelSelector.isTravel) {
      setTravelStatus(travelSelector.data.status);
    }
  }, [travelSelector]);

  function closeUpdateStatusTab() {
    dispatch({ type: 'CLOSE_UPDATE_STATUS_TAB' });
  }

  async function updateStatus(e) {
    e.preventDefault();
    setIsSaving(true);
    const userToken = localStorage.getItem('@alliancesystem/token');
    const response = await api.put(`/travels/admin/status/${travelSelector.data.id}`, { status: travelStatus }, { headers: { Authorization: userToken } });
    dispatch({ type: 'UPDATE_TRAVELS_CACHE' });
    setIsSaving(false);
  }

  if (travelSelector.isEditStatusOpen) {
    return (
      <div className="registerNotification driverNotification">
        <div className="containner">
          <a href="javascript:void(0)" onClick={closeUpdateStatusTab}><i className="fas fa-times" aria-hidden="true" /></a>
          <h1>Atualizar Status da Viagem</h1>
          <form className="alterdata" onSubmit={updateStatus}>
            <button style={{ backgroundColor: '#1a1afd' }} className="button" disabled={isSaving}>
              <ClipLoader css={{ position: 'absolute', marginLeft: '-23px', marginTop: '2px' }} color="#ffffff" loading={isSaving} size={12} />
              {' '}
              Enviar
            </button>
            <div className="field">
              <label>Status da Viagem:</label>
              <select name="divisions" value={travelStatus} onChange={(e) => setTravelStatus(e.target.value)}>
                {validationValues.map((value) => (
                  <option value={value.value} disabled={value.isDisabled}>{value.name}</option>
                ))}
              </select>
            </div>
            <div className="field">
              <label>Observações:</label>
              <br />
              <textarea
                id="obs"
                name="obs"
                value={travelObservation}
                disabled
                onChange={(e) => setTravelObservation(e.target.value)}
              />
            </div>
          </form>
        </div>
      </div>
    );
  }

  return null;
}
