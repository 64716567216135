/* eslint-disable default-param-last */
const INITIAL_STATE = {
  active: false,
  error: false,
  errorName: '',
  data: {},
};

export default function registerUser(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'REGISTER_USER':
      return {
        active: true, error: action.error, errorName: action.errorName, data: action.data,
      };
    case 'CLOSE_POPUP':
      return {
        active: false, error: false, errorName: '', data: {},
      };
    default:
      return state;
  }
}
