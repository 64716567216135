/* eslint-disable react/jsx-no-script-url */
import React from 'react';

import ASLogo from '../../images/aslogo.png';

import '../../style/Header.css';

export default function BlueHeader(props) {
  const { primaryAuthData, sectorColor } = props;
  return (
    <header className={`header ${sectorColor}`}>
      <div className="logoImg">
        <img src={ASLogo} alt="AllianceSystem Logo" />
      </div>
      <div className="barName">
        <ul>
          <li>
            <a href="javascript:void(0)">
              <span>{primaryAuthData.nickname}</span>
              &nbsp;&nbsp;
              <i className="fas fa-angle-down" style={{ fontSize: '18pt', color: 'white' }} />
            </a>
            <div className="dropdown-content">
              <a href="/alliancesystem/profile">Meu Perfil</a>
              <br />
              <a href="/alliancesystem/config">Configurações</a>
              <br />
              <a href="/alliancesystem/logoff">Sair</a>
            </div>
          </li>
        </ul>
      </div>
    </header>
  );
}
