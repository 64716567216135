import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Slide } from '@mui/material';
import Header from './components/Header';
import LoadingScreen from './LoadingScreen';

function AboutUs() {
  const state = {
    activeTab: {
      home: '',
      aboutUs: 'active',
      events: '',
      staff: '',
      apply: '',
    },
    linkWebsite: '/aboutus',
  };

  const [isLoading, setIsLoading] = React.useState(true);

  useEffect(() => {
    const onPageLoad = () => {
      setTimeout(() => { setIsLoading(false); }, 1000);
    };

    while (document.readyState === 'loading') {
      setTimeout(() => { }, 100);
    }

    onPageLoad();
  }, []);

  if (isLoading) {
    return (
      <Slide direction="up" in={isLoading} enter={false} unmountOnExit>
        <div>
          <LoadingScreen />
        </div>
      </Slide>
    );
  }

  return (
    <>
      <Helmet>
        <title>Sobre Nós - Alliance Virtual</title>
      </Helmet>
      <Header activeTab={state.activeTab} linkWebsite={state.linkWebsite} />
    </>
  );
}

export default AboutUs;
