import React from 'react';
import Routes from './routes';

import './global/css/default.css';

function App() {
  return <Routes />;
}

export default App;
