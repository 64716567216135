/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';

import api from '../services/MainAPI';

import Header from './components/UI_Components/Header';
import LateralNavigation from './components/UI_Components/LateralNavigation';

// Defaults CSS
import './style/default.css';

let checkLogin = false;
let userToken;

export default function Home() {
  const navigate = useNavigate();

  const [primaryAuth, setPrimaryAuth] = useState([]);

  async function checkLoginFuntion() {
    if (!checkLogin) {
      userToken = localStorage.getItem('@alliancesystem/token');
      const res = await api.get('/auth/primaryauth', {}, { headers: { Authorization: userToken } });
      if (res.data.error) {
        navigate('/alliancesystem/login');
      } else {
        setPrimaryAuth(res.data);
        checkLogin = true;
      }
    }
  }

  checkLoginFuntion();

  return (
    <>
      <Helmet>
        <title>Início - AllianceSystem</title>
      </Helmet>
      <Header primaryAuthData={primaryAuth} sectorColor="blueHeader" />
      <LateralNavigation primaryAuthData={primaryAuth} navigationSector="HOME" />
    </>
  );
}
