import React, { useEffect, useState } from 'react';

import api from '../../../services/MainAPI';

import '../css/home.css';

export default function Homepage() {
  const [rankingData, setRankingData] = useState([]);
  const [trackerPersonalData, setTrackerPersonalData] = useState({});
  const [trackerCompanyData, setTrackerCompanyData] = useState({});

  useEffect(async () => {
    const userToken = localStorage.getItem('@alliancesystem/token');

    const month = (new Date().getUTCMonth() + 1);
    const year = new Date().getUTCFullYear();

    const res = await api.post('/travels/ranking', { month, year }, { headers: { Authorization: userToken } });
    setRankingData(res.data.slice(0, 10));

    const personalDataReq = await api.get(`/travels/monthpersonaldata/${month}/${year}`, {}, { headers: { Authorization: userToken } });
    setTrackerPersonalData(personalDataReq.data.data);

    const companyDataReq = await api.get(`/travels/monthcompanydata/${month}/${year}`, {}, { headers: { Authorization: userToken } });
    setTrackerCompanyData(companyDataReq.data.data);
  }, []);

  return (
    <main className="defaultMargin">
      <div className="travelsHomeHeader">
        <div className="personaldata">
          <h2>Visão Geral Pessoal:</h2>
          <table>
            <tr>
              <td className="personalLeft">Colocação na VTC</td>
              <td className="personalRight">{trackerPersonalData.rankingPosition}</td>
            </tr>
            <tr>
              <td className="personalLeft">Distância nesse mês</td>
              <td className="personalRight">{`${trackerPersonalData.acceptedDistanceThisMonth} km`}</td>
            </tr>
            <tr>
              <td className="personalLeft">Viagens nesse mês</td>
              <td className="personalRight">{`${trackerPersonalData.numberTravels} viagens`}</td>
            </tr>
            <tr>
              <td className="personalLeft">Distância total</td>
              <td className="personalRight">{`${trackerPersonalData.acceptedDistanceTotal} km`}</td>
            </tr>
          </table>
        </div>
        <div className="personaldata">
          <h2>Visão Geral da Empresa:</h2>
          <table>
            <tr>
              <td className="personalLeft">Distância nesse mês</td>
              <td className="personalRight">{`${trackerCompanyData.acceptedDistanceThisMonth} km`}</td>
            </tr>
            <tr>
              <td className="personalLeft">Viagens nesse mês</td>
              <td className="personalRight">{`${trackerCompanyData.numberTravels} viagens`}</td>
            </tr>
            <tr>
              <td className="personalLeft">Massa de carga nesse mês</td>
              <td className="personalRight">{`${trackerCompanyData.weightThisMonth} kg`}</td>
            </tr>
            <tr>
              <td className="personalLeft">Distância total</td>
              <td className="personalRight">{`${trackerCompanyData.acceptedDistanceTotal} km`}</td>
            </tr>
          </table>
        </div>
        <div className="toptenmonth">
          <h2>Primeiros Colocados do Mês :</h2>
          <table>
            {rankingData.map((_data) => (
              <tr>
                <td className="toptenmonthposition">{_data.positionid}</td>
                <td className="toptenmonthname">{_data.nickname}</td>
                <td className="toptenmonthkm">
                  {`${_data.kilometers} km`}
                </td>
              </tr>
            ))}
          </table>
        </div>
      </div>
    </main>
  );
}
