import React from 'react';

import Logo from './images/logo.png';

function LoadingScreen() {
  return (
    <div
      className="loading"
      style={{
        background: '#353535', width: '100vw', height: '100vh', transition: 'all 1s ease-in-out', position: 'absolute', zIndex: '100',
      }}
    >
      <div style={{
        display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '40vh', flexDirection: 'column',
      }}
      >
        <img alt="Alliance Logo" style={{ height: '200px', padding: '30px' }} className="logo-img" src={Logo} />
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  );
}

export default LoadingScreen;
