/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/aria-role */
import React, { useState, useEffect } from 'react';
import StaffMember from './StaffMember';

import api from '../../services/MainAPI';

import '../style/stafflist.css';

function StaffList() {
  const [data, setData] = useState([]);

  useEffect(() => {
    async function getData() {
      const res = await api.get('/website/getstaffdata');
      setData(res.data);
    }
    getData();
  }, []);

  return (
    <div className="staff-background">
      <div className="staff-list">
        <h1>Setor Executivo</h1>
        {data.map((_data) => {
          if (_data.departament_id === 1) {
            return (
              <StaffMember
                key={_data.member_id}
                profileImage={_data.profile_image}
                name={_data.nickname}
                role={_data.role_name}
              />
            );
          }
        })}
      </div>
      <div className="staff-list">
        <h1>Setor de Gestão Administrativa de Pessoal</h1>
        {data.map((_data) => {
          if (_data.departament_id === 2) {
            return (
              <StaffMember
                key={_data.member_id}
                profileImage={_data.profile_image}
                name={_data.nickname}
                role={_data.role_name}
              />
            );
          }
        })}
      </div>
      <div className="staff-list">
        <h1>Setor de Eventos</h1>
        {data.map((_data) => {
          if (_data.departament_id === 3) {
            return (
              <StaffMember
                key={_data.member_id}
                profileImage={_data.profile_image}
                name={_data.nickname}
                role={_data.role_name}
              />
            );
          }
        })}
      </div>
      <div className="staff-list">
        <h1>Setor de Mídia</h1>
        {data.map((_data) => {
          if (_data.departament_id === 5) {
            return (
              <StaffMember
                key={_data.member_id}
                profileImage={_data.profile_image}
                name={_data.nickname}
                role={_data.role_name}
              />
            );
          }
        })}
      </div>
      <div className="staff-list">
        <h1>Setor de Desenvolvimento</h1>
        {data.map((_data) => {
          if (_data.departament_id === 6) {
            return (
              <StaffMember
                key={_data.member_id}
                profileImage={_data.profile_image}
                name={_data.nickname}
                role={_data.role_name}
              />
            );
          }
        })}
      </div>
    </div>
  );
}

export default StaffList;
