import React, { useEffect, useState } from 'react';

import api from '../../../services/MainAPI';

import '../css/listFiredDriversInterface.css';

export default function ListFiredDriversInterface() {
  const [firedDriversData, setFiredDriversData] = useState([]);

  async function getFiredDriversData() {
    const userToken = localStorage.getItem('@alliancesystem/token');

    const res = await api.get('/gap/listfireddrivers', {}, { headers: { Authorization: userToken } });
    setFiredDriversData(res.data);
  }

  useEffect(() => {
    getFiredDriversData();
  }, []);

  return (
    <main className="defaultMargin listfireddrivers">
      <div className="upperarea">
        <li className="name">Lista de Motoristas Demitidos</li>
      </div>
      <table>
        <tr className="titles">
          <th>ID</th>
          <th>Nome Real</th>
          <th>Apelido</th>
          <th>TruckersMP</th>
          <th>Banido Permanentemente</th>
          <th>&nbsp;</th>
        </tr>
        {firedDriversData.map((data) => (
          <tr>
            <td>{data.member_id}</td>
            <td>{data.real_name}</td>
            <td>{data.nickname}</td>
            <td>{data.truckersmp_id}</td>
            <td>
              {!!data.is_banned && 'Sim'}
              {!data.is_banned && 'Não'}
            </td>
            <td><a href={`/alliancesystem/gap/fireddriver/${data.member_id}`}>Mais Detalhes</a></td>
          </tr>
        ))}
      </table>
    </main>
  );
}
