import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

// Website
import Home from './website/Home';
import AboutUs from './website/AboutUs';
import Staff from './website/Staff';
import Apply from './website/Apply';
import Events from './website/Events';

import HomeAS from './alliancesystem/Home';
import Login from './alliancesystem/Login';
import LoginCode from './alliancesystem/LoginCode';
import FailLogin from './alliancesystem/LoginFailedTemp';
import Logoff from './alliancesystem/controller/Logoff';

// GAP
import HomeGAP from './alliancesystem/gap/Home';
import RegisterUser from './alliancesystem/gap/RegisterUser';
import ListDrivers from './alliancesystem/gap/ListDrivers';
import ListFiredDrivers from './alliancesystem/gap/ListFiredDrivers';
import ListDriver from './alliancesystem/gap/ListDriver';
import ListFiredDriver from './alliancesystem/gap/ListFiredDriver';

// Travels
import TravelsHome from './alliancesystem/travels/Home';
import TravelsAdmin from './alliancesystem/travels/TravelsAdmin';
import TravelsManageDataName from './alliancesystem/travels/ManageDataName';
import TrackerDownload from './alliancesystem/travels/TrackerDownload';
import Password from './alliancesystem/travels/Password';
import ListTravels from './alliancesystem/travels/ListTravels';

function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/aboutus" element={<AboutUs />} />
        <Route exact path="/staff" element={<Staff />} />
        <Route exact path="/apply" element={<Apply />} />

        <Route exact path="/alliancesystem" element={<HomeAS />} />
        <Route exact path="/alliancesystem/login" element={<Login />} />
        <Route exact path="/alliancesystem/login/:code" element={<LoginCode />} />
        <Route exact path="/alliancesystem/faillogin" element={<FailLogin />} />
        <Route exact path="/alliancesystem/logoff" element={<Logoff />} />

        <Route exact path="/alliancesystem/gap" element={<HomeGAP />} />
        <Route exact path="/alliancesystem/gap/registeruser" element={<RegisterUser />} />
        <Route exact path="/alliancesystem/gap/drivers" element={<ListDrivers />} />
        <Route exact path="/alliancesystem/gap/fireddrivers" element={<ListFiredDrivers />} />
        <Route exact path="/alliancesystem/gap/driver/:id" element={<ListDriver />} />
        <Route exact path="/alliancesystem/gap/fireddriver/:id" element={<ListFiredDriver />} />

        <Route exact path="/alliancesystem/travels" element={<TravelsHome />} />
        <Route exact path="/alliancesystem/travels/admin" element={<TravelsAdmin />} />
        <Route exact path="/alliancesystem/travels/dataname" element={<TravelsManageDataName />} />
        <Route exact path="/alliancesystem/travels/download" element={<TrackerDownload />} />
        <Route exact path="/alliancesystem/travels/password" element={<Password />} />
        <Route exact path="/alliancesystem/travels/travels" element={<ListTravels />} />
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
