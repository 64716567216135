import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';

import api from '../../services/MainAPI';

import Header from '../components/UI_Components/Header';
import LateralNavigation from '../components/UI_Components/LateralNavigation';
import ListDriversInterface from './components/ListDriversInterface';

let checkLogin = false;

export default function ListDrivers() {
  const navigate = useNavigate();

  const [primaryAuth, setPrimaryAuth] = useState([]);

  async function checkLoginFunction() {
    if (!checkLogin) {
      const userToken = localStorage.getItem('@alliancesystem/token');
      const res = await api.get('/auth/primaryauth', {}, { headers: { Authorization: userToken } });
      if (res.data.error) {
        navigate('/alliancesystem/login');
      } else {
        setPrimaryAuth(res.data);
        checkLogin = true;
      }
    }
  }

  checkLoginFunction();

  return (
    <>
      <Helmet>
        <title>Lista de Motoristas - AllianceSystem</title>
      </Helmet>
      <Header primaryAuthData={primaryAuth} sectorColor="gapHeader" />
      <LateralNavigation primaryAuthData={primaryAuth} navigationSector="GAP" defaultTab={{ value: true, name: 'Voltar', url: '/gap' }} />
      <ListDriversInterface />
    </>
  );
}
