/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';

import api from '../../../services/MainAPI';

import '../../style/lateralNavigation.css';

let firtTab = false;

let getNavDataVar = false;

export default function LateralNavigation(props) {
  const { primaryAuthData, navigationSector, defaultTab } = props;

  const [navData, setNavData] = useState([]);

  async function getNavData() {
    const userToken = localStorage.getItem('@alliancesystem/token');
    const resNavData = await api.get(`/interface/navigationbar/${navigationSector}`, {}, { headers: { Authorization: userToken } });
    if (defaultTab !== undefined) {
      if (defaultTab.value === true) {
        firtTab = true;
      }
    }

    setNavData(resNavData.data);
    getNavDataVar = true;
  }

  if (!getNavDataVar) {
    getNavData();
  }

  return (
    <nav className="lateralNavigation">
      <div className="personalInfo">
        <h2>{primaryAuthData.nickname}</h2>
        <h3>{primaryAuthData.rolename}</h3>
      </div>
      <div className="navigation">
        <ul>
          {firtTab && <li><a href={`/alliancesystem${defaultTab.url}`}>{defaultTab.name}</a></li>}
          {navData.map((nav) => (
            <li key={nav.name}><a href={`/alliancesystem${nav.url}`}>{nav.name}</a></li>
          ))}
        </ul>
      </div>
    </nav>
  );
}
