/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
/* eslint-disable react/button-has-type */
/* eslint-disable react/self-closing-comp */
/* eslint-disable no-undef */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import Switch from 'react-switch';
import Collapsible from 'react-collapsible';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import { useDispatch } from 'react-redux';

import api from '../../../services/MainAPI';
import truckersmpidapi from '../../../services/TruckersMPAPI';

import '../css/registerUserInterface.css';

export default function RegisterUserInterface() {
  const dispatch = useDispatch();

  // DataForm
  const [specialNeed, setSpecialNeed] = useState([]);
  const [countries, setCountries] = useState([]);
  const [divisions, setDivisions] = useState([]);
  const [games, setGames] = useState([]);
  const [skills, setSkills] = useState([]);
  const [truckersmpname, setTruckersmpName] = useState('');

  // FormData
  const [realName, setRealName] = useState('');
  const [nickname, setNickname] = useState('');
  const [email, setEmail] = useState('');
  const [truckersmpid, setTruckersmpid] = useState('');
  const [trucksbook, setTrucksbook] = useState('');
  const [birthday, setBirthday] = useState('');
  const [specialneedData, setSpecialNeedData] = useState('');
  const [country, setCountry] = useState('');
  const [division, setDivision] = useState('');

  const [observation, setObservation] = useState('');

  // System
  const [isRegistering, setIsRegistering] = useState(false);

  async function getDataToForm() {
    const userToken = localStorage.getItem('@alliancesystem/token');

    const responseSpecialNeed = await api.get('/interface/provider/specialneed', {}, { headers: { Authorization: userToken } });
    setSpecialNeed(responseSpecialNeed.data);
    setSpecialNeedData(responseSpecialNeed.data[0].special_need_id);

    const responseCountries = await api.get('/interface/provider/countries', {}, { headers: { Authorization: userToken } });
    setCountries(responseCountries.data);
    setCountry(responseCountries.data[0].country_id);

    const responseDivison = await api.get('/interface/provider/driverroles', {}, { headers: { Authorization: userToken } });
    setDivisions(responseDivison.data);
    setDivision(responseDivison.data[0].role_id);

    const responseGames = await api.get('/interface/provider/games', {}, { headers: { Authorization: userToken } });
    setGames(responseGames.data);

    const responseSkills = await api.get('/interface/provider/skills', {}, { headers: { Authorization: userToken } });
    setSkills(responseSkills.data);
  }

  async function getNicknameTMP(tmpid) {
    try {
      setTimeout(() => { }, 2000);

      if (tmpid === truckersmpid) {
        const tmpresponse = await axios.get(`https://api.truckyapp.com/v2/truckersmp/player?playerID=${tmpid}`);
        if (tmpresponse.data.response.error === true) {
          setTruckersmpName('');
        } else {
          setTruckersmpName(tmpresponse.data.response.response.name);
        }
      }
    } catch (error) {
      setTruckersmpName('');
    }
  }

  function registerUserRedux(error, errorName, data) {
    dispatch({
      type: 'REGISTER_USER', error, errorName, data,
    });
  }

  async function registerUser(e) {
    e.preventDefault();
    if (!isRegistering) {
      setIsRegistering(true);

      const userToken = localStorage.getItem('@alliancesystem/token');
      const response = await api.post('/gap/createuseradmin', {
        realname: realName, email, birthday, nickname, truckersmpid, trucksbookid: trucksbook, specialNeed: specialneedData, country, division, staff_comment: observation, skills, games,
      }, { headers: { Authorization: userToken } });

      const resData = response.data;
      if (response.data.error) {
        registerUserRedux(resData.error, resData.errorName, {});
      } else {
        registerUserRedux(resData.error, '', {
          realname: resData.realname, nickname: resData.nickname, truckersmpnickname: resData.truckersmpname, discordcode: resData.discordCode,
        });
      }
      setIsRegistering(false);
    }
  }

  useEffect(() => {
    getDataToForm();
  }, []);

  useEffect(() => {
    getNicknameTMP(truckersmpid);
  }, [truckersmpid]);

  return (
    <main className="defaultMargin registerUser">
      <div className="areaname">
        <div className="directory">
          <ul>
            <li className="name">Cadastrar Motorista</li>
          </ul>

        </div>
      </div>

      <form className="registeruser" onSubmit={registerUser}>
        <button className="button">
          <ClipLoader css={{ position: 'absolute', marginLeft: '-23px', marginTop: '2px' }} color="#ffffff" loading={isRegistering} size={12} />
          {' '}
          Enviar
        </button>
        <div className="mainInfo">
          <div className="tripleform">
            <div className="field">
              <input
                type="text"
                id="realname"
                name="realname"
                required
                value={realName}
                onChange={(e) => setRealName(e.target.value)}
              />
              <label htmlFor="realname">Nome Completo:</label>
            </div>
            <div className="field">
              <input
                type="text"
                id="nickname"
                name="nickname"
                required
                value={nickname}
                onChange={(e) => setNickname(e.target.value)}
              />
              <label htmlFor="nickname">Apelido: </label>
            </div>
            <div className="field">
              <input
                type="text"
                id="email"
                name="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <label htmlFor="email">E-mail: </label>
            </div>
          </div>
          <div className="tripleform">
            <div className="field">
              <label htmlFor="truckersmpid">
                TruckersMPID:
                <span className="truckersmpname">{truckersmpname}</span>
              </label>
              <input
                type="text"
                id="truckersmpid"
                name="truckersmpid"
                required
                value={truckersmpid}
                onChange={(e) => setTruckersmpid(e.target.value)}
              />
            </div>
            <div className="field">
              <label htmlFor="trucksbookid">Trucksbook: </label>
              <input
                type="text"
                id="trucksbook"
                name="trucksbook"
                value={trucksbook}
                onChange={(e) => setTrucksbook(e.target.value)}
              />
            </div>
            <div className="field">
              <label htmlFor="truckersmpid">Data de Aniversário: </label>
              <input
                type="date"
                id="birthday"
                name="birthday"
                required
                value={birthday}
                onChange={(e) => setBirthday(e.target.value)}
              />
            </div>
          </div>
          <div className="tripleform">
            <div className="field">
              <label htmlFor="specialneed">Necessidade Especiais:</label>
              <select name="specialneed" onChange={(e) => setSpecialNeedData(e.target.value)}>
                {specialNeed.map((data) => (
                  <option value={data.special_need_id}>{data.description}</option>
                ))}
              </select>
            </div>
            <div className="field">
              <label>País:</label>
              <select name="country" onChange={(e) => setCountry(e.target.value)}>
                {countries.map((data) => (
                  <option value={data.country_id}>{data.name}</option>
                ))}
              </select>
            </div>
            <div className="field">
              <label>Divisão:</label>
              <select name="divisions" onChange={(e) => setDivision(e.target.value)}>
                {divisions.map((data) => (
                  <option value={data.role_id}>{data.name}</option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="borderCollapsible">
          <Collapsible trigger="🔧 Habilidades" className="skills">
            <div className="skills">
              {skills.map((skill) => (
                <div className="switch">
                  <label>
                    <Switch
                      height={20}
                      width={40}
                      checked={skill.value}
                      onChange={(e) => setSkills(
                        skills.map((x, i) => ((x.skill_id === skill.skill_id) ? {
                          skill_id: x.skill_id,
                          description: x.description,
                          value: (e ? 1 : 0),
                        } : x)),
                      )}
                    />
                    <span>
                      {skill.description}
                    </span>
                  </label>
                </div>
              ))}
            </div>
          </Collapsible>
          <Collapsible trigger="🚚 Jogos e DLCs" className="games">
            <div className="games">
              {games.map((game) => {
                if (game.description.endsWith('Euro Truck Simulator') || game.name.startsWith('Euro')) {
                  return (
                    <div className="switch">
                      <label>
                        <Switch
                          height={20}
                          width={40}
                          checked={game.value}
                          onChange={(e) => setGames(
                            games.map((x, i) => (x.game_id === game.game_id ? {
                              game_id: x.game_id,
                              name: x.name,
                              description: x.description,
                              value: (e ? 1 : 0),
                            } : x)),
                          )}
                        />
                        <span>{game.name}</span>
                      </label>
                    </div>
                  );
                }
                return null;
              })}
              <br />
              <br />
              {games.map((game) => {
                if (game.description.endsWith('American Truck Simulator') || game.name.startsWith('American')) {
                  return (
                    <div className="switch">
                      <label>
                        <Switch
                          height={20}
                          width={40}
                          checked={game.value}
                          onChange={(e) => setGames(
                            games.map((x, i) => (x.game_id === game.game_id ? {
                              game_id: x.game_id,
                              name: x.name,
                              description: x.description,
                              value: (e ? 1 : 0),
                            } : x)),
                          )}
                        />
                        <span>{game.name}</span>
                      </label>
                    </div>
                  );
                }
                return null;
              })}
            </div>
          </Collapsible>
          <Collapsible trigger="💬 Observações" className="observations">
            <textarea
              id="obs"
              name="obs"
              value={observation}
              onChange={(e) => setObservation(e.target.value)}
            >
            </textarea>
          </Collapsible>
        </div>
      </form>
    </main>
  );
}
