/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { ClipLoader } from 'react-spinners';

import api from '../../../services/MainAPI';

import '../css/password.css';

import Header from '../../components/UI_Components/Header';
import LateralNavigation from '../../components/UI_Components/LateralNavigation';

import StatusPopup from './StatusPopup';

let checkLogin = false;

export default function SavePasswordForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const popupData = useSelector((state) => state.manageTravel.popup);

  const [primaryAuth, setPrimaryAuth] = useState({});

  const [formData, setFormData] = useState({});

  const [isSaving, setIsSaving] = useState(false);

  async function checkLoginFunction() {
    if (!checkLogin) {
      const userToken = localStorage.getItem('@alliancesystem/token');
      const res = await api.get('/auth/primaryauth', {}, { headers: { Authorization: userToken } });
      if (res.data.error) {
        navigate('/alliancesystem/login');
      } else {
        setPrimaryAuth(res.data);
        checkLogin = true;
      }
    }
  }

  async function registerPassword(e) {
    e.preventDefault();
    setIsSaving(true);
    const userToken = localStorage.getItem('@alliancesystem/token');
    const response = await api.post('/travels/password', formData, { headers: { Authorization: userToken } });
    if (response.data.error) {
      if (response.data.errorName === 'PASSWORD_ISNT_THE_SAME') {
        dispatch({ type: 'CREATE_POPUP', message: 'As senhas digitadas não coincidem.', popupType: 'FAILED' });
      } else {
        dispatch({ type: 'CREATE_POPUP', message: 'Ocorreu um erro desconhecido.', popupType: 'FAILED' });
      }
    } else {
      dispatch({ type: 'CREATE_POPUP', message: 'Sua senha foi registrada com sucesso.', popupType: 'SUCCESS' });
    }

    setIsSaving(false);
  }

  checkLoginFunction();

  return (
    <>

      <Header primaryAuthData={primaryAuth} sectorColor="blueHeader" />
      <LateralNavigation primaryAuthData={primaryAuth} navigationSector="TRAVELS" defaultTab={{ value: true, name: 'Voltar às Viagens', url: '/travels' }} />
      <StatusPopup isShowing={popupData.isPopupOpen} text={popupData.popupMessage} type={popupData.popupType} height="50px" dispatchName="CLOSE_POPUP" />
      <main className="defaultMargin trackerDownload" id="travelsAdmin">
        <h1>Cadastre uma Senha:</h1>
        <form style={{ marginTop: '-10px', marginLeft: '20px' }} onSubmit={registerPassword}>
          <div className="field fieldSpace passwordForm">
            <label>Digite sua nova senha:</label>
            <br />
            <input type="password" value={formData.password} onChange={(e) => { setFormData({ ...formData, password: e.target.value }); }} />
            <br />
            <br />
            <label>Confirme sua nova senha:</label>
            <br />
            <input type="password" value={formData.confirmedPassword} onChange={(e) => { setFormData({ ...formData, confirmedPassword: e.target.value }); }} />
            <br />
            <br />
            <button className="button" disabled={isSaving}>
              <ClipLoader css={{ position: 'absolute', marginLeft: '-23px', marginTop: '2px' }} color="#ffffff" loading={isSaving} size={12} />
              {' '}
              Salvar Senha
            </button>
          </div>
        </form>
      </main>
    </>
  );
}
