/* eslint-disable default-param-last */
const INITIAL_STATE = {
  driverData: false,
  gamesData: false,
  skillsData: false,
  alterloa: false,
  firedriver: false,
  reload: true,
};

export default function driverGap(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'OPEN_DRIVER_DATA':
      return { ...state, driverData: true };
    case 'CLOSE':
      return {
        ...state,
        driverData: false,
        gamesData: false,
        skillsData: false,
        alterloa: false,
        firedriver: false,
      };
    case 'OPEN_GAMES_DATA':
      console.log('Redux');
      console.log(state.gamesData);
      return { ...state, gamesData: true };
    case 'OPEN_SKILLS_DATA':
      return { ...state, skillsData: true };
    case 'OPEN_ALTER_LOA':
      return { ...state, alterloa: true };
    case 'OPEN_FIRE_DRIVER':
      return { ...state, firedriver: true };
    case 'RELOAD':
      return { ...state, reload: true };
    case 'UPDATED':
      return { ...state, reload: false };
    default:
      return state;
  }
}
