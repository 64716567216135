import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Provider } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import api from '../../services/MainAPI';
import store from '../../store';

import Header from '../components/UI_Components/Header';
import LateralNavigation from '../components/UI_Components/LateralNavigation';
import ListDriverInterface from './components/ListDriverInterface';
import ListDriverNotification from './components/ListDriverNotification';

let checkLogin = false;

export default function ListDriver() {
  const navigate = useNavigate();

  const [primaryAuth, setPrimaryAuth] = useState([]);

  async function checkLoginFunction() {
    if (!checkLogin) {
      const userToken = localStorage.getItem('@alliancesystem/token');
      const res = await api.get('/auth/primaryauth', {}, { headers: { Authorization: userToken } });
      if (res.data.error) {
        navigate('/alliancesystem/login');
      } else {
        setPrimaryAuth(res.data);
        checkLogin = true;
      }
    }
  }

  checkLoginFunction();

  return (
    <>
      <Helmet>
        <title>Dados do Motorista - AllianceSystem</title>
      </Helmet>
      <Provider store={store}>
        <Header primaryAuthData={primaryAuth} sectorColor="gapHeader" />
        <LateralNavigation primaryAuthData={primaryAuth} navigationSector="GAP" defaultTab={{ value: true, name: 'Voltar', url: '/gap/drivers' }} />
        <ListDriverInterface />
        <ListDriverNotification />
      </Provider>
    </>
  );
}
