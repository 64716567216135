/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { Helmet } from 'react-helmet';
import { Provider } from 'react-redux';

import store from '../../store';
import SavePasswordForm from './components/SavePasswordForm';

export default function Password() {
  return (
    <Provider store={store}>
      <Helmet>
        <title>Cadastre uma Senha - AllianceSystem</title>
      </Helmet>
      <SavePasswordForm />
    </Provider>
  );
}
