import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import api from '../services/MainAPI';

function HomeFix() {
  const { code } = useParams();
  const navigate = useNavigate();

  async function GenerateCode() {
    const response = await api.post(`/auth/tokengen?code=${code}`);
    if (response.data.error) {
      navigate('/alliancesystem/faillogin');
    } else {
      const finalToken = `Bearer ${response.data.token}`;
      localStorage.setItem('@alliancesystem/token', finalToken);
      navigate('/alliancesystem');
    }
  }

  useEffect(() => {
    GenerateCode();
  }, []);

  return (
    <div />
  );
}

export default HomeFix;
