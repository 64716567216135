import React from 'react';

import Background from '../images/background-1.jpg';

import '../style/home1.css';

function Home1() {
  return (
    <div className="middle-1">
      <div className="container-all">
        <img
          alt="Background Index"
          style={{
            width: '100%', height: '90vh', position: 'relative', top: '-6px', objectFit: 'cover',
          }}
          className="background-1"
          src={Background}
        />
        <div className="centered">
          <h2 className="ml2" style={{ color: 'white' }}>A Nossa Meta é Você</h2>
          <p style={{ color: 'white' }}>Venha conhecer a Alliance:</p>
          <p><a className="apply" target="_blank" href="https://discord.alliancevirtual.com.br" rel="noreferrer">Clique Aqui</a></p>
        </div>
      </div>
    </div>
  );
}

export default Home1;
