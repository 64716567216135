import React from 'react';

import Logo from '../images/logo.png';

import '../style/header.css';

function Header(props) {
  const { linkWebsite, activeTab } = props;
  return (
    <header>
      <div className="main-menu">
        <div className="margin-header">
          <div className="header-1">
            <div className="logo">
              <a href={linkWebsite}><img alt="Alliance Logo" className="logo-img" src={Logo} /></a>
            </div>
            <div className="social-medias" style={{ paddingTop: '2px' }}>
              <ul>
                <li><a target="_black" aria-label="Discord" href="https://discord.gg/Zzq7Shs"><i className="fab fa-discord social-media" /></a></li>
                <li><a target="_black" aria-label="Instagram" href="https://www.instagram.com/alliancevirtual.ofc/"><i className="fab fa-instagram social-media" /></a></li>
                <li><a target="_black" aria-label="Facebook" href="https://www.facebook.com/alliancevirtual.ofc/"><i className="fab fa-facebook social-media" /></a></li>
                <li><a target="_black" aria-label="Youtube" href="https://www.youtube.com/channel/UCr3wgPmKEsoJSsoQS6cEqvQ"><i className="fab fa-youtube social-media" /></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="main-nav">
        <div className="header-2">
          <nav className="header">
            <div className="menu">
              <ul>
                <li><a className={activeTab.home} href="/">Início</a></li>
                <li><a className={activeTab.aboutUs} href="/aboutus">Sobre</a></li>
                <li><a className={activeTab.events} href="https://truckersmp.com/vtc/1271/events" target="_blank" rel="noreferrer">Eventos</a></li>
                <li><a className={activeTab.staff} href="/staff">Nossa Equipe</a></li>
                <li><a className={activeTab.apply} href="/apply">Aplique-se</a></li>
              </ul>
            </div>
            <div className="as-access">
              <a href="/alliancesystem">Acesso Restrito</a>
            </div>
          </nav>
        </div>
      </div>
    </header>
  );
}

export default Header;
