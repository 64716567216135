/* eslint-disable default-param-last */
const INITIAL_STATE = {
  type: 'SELECT_AN_OPTION',
  isSidebarOpen: false,
  isCreateTabOpen: false,
  reloadData: false,
  sidebarData: {},
  popup: {
    isPopupOpen: false,
    popupMessage: '',
    popupType: '',
  },
};

export default function manageTravel(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'UPDATE_DATATYPE':
      return {
        ...state, isCreateTabOpen: false, isSidebarOpen: false, type: action.datatype,
      };
    case 'EDIT_SIDEBAR_DATA':
      return {
        ...state, isCreateTabOpen: false, isSidebarOpen: true, sidebarData: action.data,
      };
    case 'OPEN_CREATE_TAB':
      return { ...state, isSidebarOpen: true, isCreateTabOpen: true };
    case 'RELOAD_DATA':
      return { ...state, reloadData: true };
    case 'CANCEL_RELOAD':
      return { ...state, reloadData: false };
    case 'CREATE_POPUP':
      return {
        ...state,
        popup:
          { isPopupOpen: true, popupMessage: action.message, popupType: action.popupType },
      };
    case 'CLOSE_POPUP':
      return { ...state, popup: { isPopupOpen: false } };
    default:
      return state;
  }
}
