import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Slide } from '@mui/material';
import AboutUsInHome from './components/AboutUsInHome';
import Footer from './components/Footer';

import Header from './components/Header';
import Home1 from './components/Home1';
import Home2 from './components/Home2';
import LoadingScreen from './LoadingScreen';

function Home() {
  const state = {
    activeTab: {
      home: 'active',
      aboutUs: '',
      events: '',
      staff: '',
      apply: '',
    },
    linkWebsite: '/',
  };

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const onPageLoad = () => {
      setTimeout(() => { setIsLoading(false); }, 1000);
    };

    while (document.readyState === 'loading') {
      setTimeout(() => { }, 100);
    }

    onPageLoad();
  }, []);

  if (isLoading) {
    return (
      <Slide direction="up" in={isLoading} enter={false} unmountOnExit>
        <div>
          <LoadingScreen />
        </div>
      </Slide>
    );
  }

  return (
    <>
      <Helmet>
        <title>Início - Alliance Virtual</title>
      </Helmet>
      <Header activeTab={state.activeTab} linkWebsite={state.linkWebsite} />
      <main>
        <Home1 />
        <AboutUsInHome />
        <Home2 />
      </main>
      <Footer />
    </>
  );
}

export default Home;
