import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

export default function SidebarAdminTravels() {
  const travelSelector = useSelector((state) => state.travels);

  const dispatch = useDispatch();

  function openUpdateStatusTab() {
    dispatch({ type: 'OPEN_UPDATE_STATUS_TAB' });
  }

  function openManageDataName() {
    window.open('/alliancesystem/travels/dataname', '_blank');
  }

  if (travelSelector.isTravel) {
    const {
      cargo, city, company, trip, truck, userNickname, status,
    } = travelSelector.data;
    return (
      <>
        <div className="travelButtons">
          <button onClick={openUpdateStatusTab} type="button">Editar Status</button>
          <button type="button" disabled>Editar Dados da Viagem</button>
          <button onClick={openManageDataName} type="button">Gerenciar Banco de Nomes</button>
        </div>
        <div className="travelInfo">
          <h2>Resumo da Viagem:</h2>
          <table>
            <tbody>
              <tr>
                <td>Motorista</td>
                <td>{userNickname}</td>
              </tr>
              <tr>
                <td>Cidade de Origem</td>
                {!city.isCitySource && <td style={{ fontStyle: 'italic' }}>{city.citySource}</td>}
                {city.isCitySource && <td>{city.citySource}</td>}
              </tr>
              <tr>
                <td>Empresa de Origem</td>
                {!company.isCompanySource && <td style={{ fontStyle: 'italic' }}>{company.companySource}</td>}
                {company.isCompanySource && <td>{company.companySource}</td>}
              </tr>
              <tr>
                <td>Cidade de Destino</td>
                {!city.isCityDestination && <td style={{ fontStyle: 'italic' }}>{city.cityDestination}</td>}
                {city.isCityDestination && <td>{city.cityDestination}</td>}
              </tr>
              <tr>
                <td>Empresa de Destino</td>
                {!company.isCompanyDestination && <td style={{ fontStyle: 'italic' }}>{company.companyDestination}</td>}
                {company.isCompanyDestination && <td>{company.companyDestination}</td>}
              </tr>
              <tr>
                <td>Caga Transportada</td>
                {!cargo.isCargoName && <td style={{ fontStyle: 'italic' }}>{cargo.cargo}</td>}
                {cargo.isCargoName && <td>{cargo.cargo}</td>}
              </tr>
              <tr>
                <td>Peso da Carga</td>
                <td>
                  {cargo.weight}
                  {' '}
                  Kg
                </td>
              </tr>
              <tr>
                <td>Lucro da Carga</td>
                <td>
                  €
                  {' '}
                  {cargo.income}
                </td>
              </tr>
              <tr>
                <td>Distância Planejada</td>
                <td>
                  {trip.plannedDistance}
                  {' '}
                  Km
                </td>
              </tr>
              <tr>
                <td>Distância Percorrida</td>
                <td>
                  {trip.drivenDistance}
                  {' '}
                  Km
                </td>
              </tr>
              <tr>
                <td>Distância Aceita</td>
                <td>
                  {trip.acceptedDistance}
                  {' '}
                  Km
                </td>
              </tr>
              <tr>
                <td>Média de Consumo:</td>
                <td>
                  {trip.averageConsumption}
                  {' '}
                  Km/L
                </td>
              </tr>
              <tr>
                <td>Caminhão Utilizado</td>
                <td>{truck}</td>
              </tr>
              <tr>
                <td>Status da Viagem:</td>
                {status === 'PENDING' && <td>Pendente</td>}
                {status === 'APPROVED' && <td>Aprovado</td>}
                {status === 'REPROVED' && <td>Reprovado</td>}
                {status === 'IN EVALUATION' && <td>Em Avaliação</td>}
              </tr>
            </tbody>
          </table>
        </div>
      </>
    );
  }

  return (
    <>
    </>
  );
}
