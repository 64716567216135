import React from 'react';

import Background from '../images/background-2.jpg';

import '../style/home2.css';

function Home2() {
  return (
    <div className="middle-3">
      <div className="container-all">
        <img
          alt="Background 2"
          style={{
            width: '100%', height: '90vh', position: 'relative', objectFit: 'cover',
          }}
          className="background-1"
          src={Background}
        />
        <div className="centered">
          <h2 style={{ color: '#fff' }}>Mas Por Que Escolher a Alliance?</h2>
          <br />
          <ul>
            <li>Eventos Exclusivos</li>
            <li>Ferramenta de Telemetria</li>
            <li>Ambiente Amigável</li>
          </ul>
          <p style={{ color: '#fff' }}>Gostou? Conheça Nosso Servidor do Discord:</p>
          <p><a className="apply" target="_blank" href="https://discord.alliancevirtual.com.br/" rel="noreferrer">Clique Aqui</a></p>
        </div>
      </div>
    </div>
  );
}

export default Home2;
