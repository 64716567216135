/* eslint-disable max-len */
import React, { useState } from 'react';
import Helmet from 'react-helmet';
import { useNavigate } from 'react-router-dom';

import api from '../../services/MainAPI';

import './css/download.css';

import Header from '../components/UI_Components/Header';
import LateralNavigation from '../components/UI_Components/LateralNavigation';

let checkLogin = false;

export default function TrackerDownload() {
  const navigate = useNavigate();

  const [primaryAuth, setPrimaryAuth] = useState([]);

  async function checkLoginFunction() {
    if (!checkLogin) {
      const userToken = localStorage.getItem('@alliancesystem/token');
      const res = await api.get('/auth/primaryauth', {}, { headers: { Authorization: userToken } });
      if (res.data.error) {
        navigate('/alliancesystem/login');
      } else {
        setPrimaryAuth(res.data);
        checkLogin = true;
      }
    }
  }

  checkLoginFunction();
  return (
    <>
      <Helmet>
        <title>Download do Tracker - AllianceSystem</title>
      </Helmet>
      <Header primaryAuthData={primaryAuth} sectorColor="blueHeader" />
      <LateralNavigation primaryAuthData={primaryAuth} navigationSector="TRAVELS" defaultTab={{ value: true, name: 'Voltar às Viagens', url: '/travels' }} />
      <main className="defaultMargin trackerDownload" id="travelsAdmin">
        <h1>Download do Alliance Tracker:</h1>
        <p>
          Para baixar o Alliance Tracker, siga o seguinte tutorial:
          <br />
          <br />
          Primeiramente, baixe o
          {' '}
          <a href="http://alliancevirtual.com.br:3002/files/client/Setup-AllianceTracker.msi">Setup do Alliance Tracker</a>
          {' '}
          e a
          {' '}
          <a href="http://alliancevirtual.com.br:3002/files/client/alliancetracker-telemetry.dll">DLL de telemetria</a>
          .
          <br />
          <br />
          Após baixar os arquivos, execute, preferencialmente como administrador, o
          {' '}
          <span style={{ fontWeight: 'bold' }}>Setup-AllianceTracker.msi</span>
          {' '}
          instalando o Alliance Tracker.
          <br />
          <br />
          Depois de instalado, coloque a DLL alliancetracker-telemetry.dll na pasta (Biblioteca da Steam)\steamapps\common\Euro Truck Simulator 2\bin\win_x64\plugins .
          <br />
          <br />
          Por último, abra o Alliance Tracker, logue e faça uma viagem curta (menos de 100 Km) para testar. Se você ainda não criou uma senha para acessar o Tracker, acesse o AllianceSystem e crie.
        </p>
      </main>
    </>
  );
}
