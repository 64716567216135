import { combineReducers } from 'redux';

import registerUser from './registerUser';
import driverGap from './driverGap';
import travels from './travels';
import manageTravel from './manageTravel';

export default combineReducers({
  registerUser, driverGap, travels, manageTravel,
});
