/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React from 'react';

import AboutUsImage from '../images/about-us-index.jpg';

import '../style/aboutusinhome.css';

function AboutUsInHome() {
  return (
    <div className="middle-2">
      <div className="middle-2-1">
        <h2>Um Pouco Sobre a Alliance</h2>
        <p>Fundada em 27 de Maio de 2014, inicialmente como um grupo de amigos chamado "Company National of Journey" (BR-CNJ), tinha como objetivo proporcionar rodagens e comboios entre amigos. Mas diante de tantos acontecimentos, o dia 01 de Março de 2018 foi uma marca histórica para todos da antiga BR-CNJ, pois demos o nosso passo rumo a nossa primeira evolução, dando início a uma nova estrutura e deixando de ser um simples grupo para ser uma empresa virtual no ramo de simulação. </p>
        <p style={{ textAlign: 'center' }}><a className="apply" href="/aboutus">Conheça Mais Sobre a Alliance</a></p>
      </div>
      <div className="middle-2-2">
        <img alt="About Us" src={AboutUsImage} />
      </div>
    </div>
  );
}

export default AboutUsInHome;
