/* eslint-disable react/jsx-boolean-value */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import api from '../../../services/MainAPI';
import StatusPopup from './StatusPopup';

export default function SidebarDataName() {
  const dispatch = useDispatch();

  const travelSelector = useSelector((state) => state.manageTravel);

  const [localData, setLocalData] = useState({});
  const [countries, setCountries] = useState([]);

  const [savingData, setSavingData] = useState(false);

  useEffect(async () => {
    setLocalData(travelSelector.sidebarData);
    if (travelSelector.type) {
      const userToken = localStorage.getItem('@alliancesystem/token');
      const response = await api.get('/travels/country', {}, { headers: { Authorization: userToken } });
      setCountries(response.data.countriesdata);
    }
  }, [travelSelector.sidebarData, travelSelector.isCreateTabOpen]);

  function createDataDispatch() {
    dispatch({ type: 'OPEN_CREATE_TAB' });
    setLocalData({});
  }

  async function registerNewCity() {
    setSavingData(true);
    const city = localData.name;
    const userToken = localStorage.getItem('@alliancesystem/token');
    if (localData.name === undefined || localData.cityid === undefined) {
      dispatch({ type: 'CREATE_POPUP', message: 'Você não pode criar uma cidade com nome e/ou identificador vazio.', popupType: 'FAILED' });
    } else {
      const response = await api.post('/travels/city', { name: localData.name, cityid: localData.cityid, country: localData.countryid }, { headers: { Authorization: userToken } });
      if (!response.data.error) {
        dispatch({ type: 'RELOAD_DATA' });
        dispatch({ type: 'CREATE_POPUP', message: `A cidade ${city} foi registrada com sucesso`, popupType: 'SUCCESS' });
      } else if (response.data.errorName === 'SAME_CITY_FOUND_IN_DATABASE') {
        dispatch({ type: 'CREATE_POPUP', message: 'Foi encontrada uma cidade com o mesmo nome e/ou identificador no banco de dados.', popupType: 'FAILED' });
      }
    }
    setSavingData(false);
  }

  async function updateCity() {
    setSavingData(true);
    const city = localData.name;
    const userToken = localStorage.getItem('@alliancesystem/token');
    if (localData.name === undefined || localData.cityid === undefined) {
      dispatch({ type: 'CREATE_POPUP', message: 'Você não pode editar o nome e/ou identificador de uma cidade colocando o valor vazio.', popupType: 'FAILED' });
    } else {
      const response = await api.put(`/travels/city/${localData.id}`, { name: localData.name, cityid: localData.cityid, country: localData.countryid }, { headers: { Authorization: userToken } });
      if (!response.data.error) {
        dispatch({ type: 'RELOAD_DATA' });
        dispatch({ type: 'CREATE_POPUP', message: `Os dados da cidade ${city} foram alterados com sucesso`, popupType: 'SUCCESS' });
      } else if (response.data.errorName === 'NOTHING_TO_UPDATE') {
        dispatch({ type: 'CREATE_POPUP', message: 'Você não está enviando uma alteração sem mudar nenhuma das informações.', popupType: 'FAILED' });
      }
    }
    setSavingData(false);
  }

  async function deleteCity() {
    dispatch({ type: 'CREATE_POPUP', message: 'Por questão de segurança, essa função se encontra desabilitada. Para utilizá-la, procure pelo Setor de Desenvolvimento.', popupType: 'WARNING' });
  }

  async function createCompany() {
    setSavingData(true);
    const userToken = localStorage.getItem('@alliancesystem/token');
    if (localData.name === undefined || localData.companyid === undefined) {
      dispatch({ type: 'CREATE_POPUP', message: 'Você não pode criar uma empresa com nome e/ou identificador vazio.', popupType: 'FAILED' });
    } else {
      const response = await api.post('/travels/company', { name: localData.name, companyid: localData.companyid }, { headers: { Authorization: userToken } });
      if (!response.data.error) {
        dispatch({ type: 'RELOAD_DATA' });
        dispatch({ type: 'CREATE_POPUP', message: `A empresa ${localData.name} foi registrada com sucesso`, popupType: 'SUCCESS' });
      } else if (response.data.errorName === 'SAME_COMPANY_FOUND_IN_DATABASE') {
        dispatch({ type: 'CREATE_POPUP', message: 'Foi encontrada uma empresa com o mesmo nome e/ou identificador no banco de dados.', popupType: 'FAILED' });
      } else {
        dispatch({ type: 'CREATE_POPUP', message: 'Ocorreu um erro desconhecido. Para mais informações, contacte um Desenvolvedor', popupType: 'FAILED' });
      }
    }
    setSavingData(false);
  }

  async function updateCompany() {
    setSavingData(true);
    const userToken = localStorage.getItem('@alliancesystem/token');
    if (localData.name === undefined || localData.companyid === undefined) {
      dispatch({ type: 'CREATE_POPUP', message: 'Você não pode editar o nome e/ou identificador de uma empresa colocando o valor vazio.', popupType: 'FAILED' });
    } else {
      const response = await api.put(`/travels/company/${localData.id}`, { name: localData.name, companyid: localData.companyid }, { headers: { Authorization: userToken } });
      if (!response.data.error) {
        dispatch({ type: 'RELOAD_DATA' });
        dispatch({ type: 'CREATE_POPUP', message: `Os dados da empresa ${localData.name} foram alterados com sucesso`, popupType: 'SUCCESS' });
      } else if (response.data.errorName === 'NOTHING_TO_UPDATE') {
        dispatch({ type: 'CREATE_POPUP', message: 'Você não está enviando uma alteração sem mudar nenhuma das informações.', popupType: 'FAILED' });
      } else {
        dispatch({ type: 'CREATE_POPUP', message: 'Ocorreu um erro desconhecido. Para mais informações, contacte um Desenvolvedor', popupType: 'FAILED' });
      }
    }
    setSavingData(false);
  }

  async function deleteCompany() {
    dispatch({ type: 'CREATE_POPUP', message: 'Por questão de segurança, essa função se encontra desabilitada. Para utilizá-la, procure pelo Setor de Desenvolvimento.', popupType: 'WARNING' });
  }

  async function createCargo() {
    setSavingData(true);
    const userToken = localStorage.getItem('@alliancesystem/token');
    if (localData.name === undefined || localData.cargoid === undefined) {
      dispatch({ type: 'CREATE_POPUP', message: 'Você não pode criar uma carga com nome e/ou identificador vazio.', popupType: 'FAILED' });
    } else {
      const response = await api.post('/travels/cargo', { name: localData.name, cargoid: localData.cargoid }, { headers: { Authorization: userToken } });
      if (!response.data.error) {
        dispatch({ type: 'RELOAD_DATA' });
        dispatch({ type: 'CREATE_POPUP', message: `A carga ${localData.name} foi registrada com sucesso`, popupType: 'SUCCESS' });
      } else if (response.data.errorName === 'SAME_CARGO_FOUND_IN_DATABASE') {
        dispatch({ type: 'CREATE_POPUP', message: 'Foi encontrada uma carga com o mesmo nome e/ou identificador no banco de dados.', popupType: 'FAILED' });
      } else {
        dispatch({ type: 'CREATE_POPUP', message: 'Ocorreu um erro desconhecido. Para mais informações, contacte um Desenvolvedor', popupType: 'FAILED' });
      }
    }
    setSavingData(false);
  }

  async function updateCargo() {
    setSavingData(true);
    const userToken = localStorage.getItem('@alliancesystem/token');
    if (localData.name === undefined || localData.cargoid === undefined) {
      dispatch({ type: 'CREATE_POPUP', message: 'Você não pode editar o nome e/ou identificador de uma cargo colocando o valor vazio.', popupType: 'FAILED' });
    } else {
      const response = await api.put(`/travels/company/${localData.id}`, { name: localData.name, cargoid: localData.companyid }, { headers: { Authorization: userToken } });
      if (!response.data.error) {
        dispatch({ type: 'RELOAD_DATA' });
        dispatch({ type: 'CREATE_POPUP', message: `Os dados da carga ${localData.name} foram alterados com sucesso`, popupType: 'SUCCESS' });
      } else if (response.data.errorName === 'NOTHING_TO_UPDATE') {
        dispatch({ type: 'CREATE_POPUP', message: 'Você não está enviando uma alteração sem mudar nenhuma das informações.', popupType: 'FAILED' });
      } else {
        dispatch({ type: 'CREATE_POPUP', message: 'Ocorreu um erro desconhecido. Para mais informações, contacte um Desenvolvedor', popupType: 'FAILED' });
      }
    }
    setSavingData(false);
  }

  async function deleteCargo() {
    dispatch({ type: 'CREATE_POPUP', message: 'Por questão de segurança, essa função se encontra desabilitada. Para utilizá-la, procure pelo Setor de Desenvolvimento.', popupType: 'WARNING' });
  }

  async function createCountry() {
    setSavingData(true);
    const userToken = localStorage.getItem('@alliancesystem/token');
    if (
      localData.name === undefined
      || localData.initials === undefined
      || localData.countryid === undefined) {
      dispatch({ type: 'CREATE_POPUP', message: 'Você não pode criar um país com nome e/ou identificador vazio.', popupType: 'FAILED' });
    } else {
      const response = await api.post('/travels/country', { name: localData.name, initials: localData.initials, countryid: localData.countryid }, { headers: { Authorization: userToken } });
      if (!response.data.error) {
        dispatch({ type: 'RELOAD_DATA' });
        dispatch({ type: 'CREATE_POPUP', message: `O país ${localData.name} foi registrado com sucesso`, popupType: 'SUCCESS' });
      } else if (response.data.errorName === 'COUNTRY_ALREADY_EXIST') {
        dispatch({ type: 'CREATE_POPUP', message: 'Foi encontrado um país com o mesmo nome, sigla e/ou identificador no banco de dados.', popupType: 'FAILED' });
      } else {
        dispatch({ type: 'CREATE_POPUP', message: 'Ocorreu um erro desconhecido. Para mais informações, contacte um Desenvolvedor', popupType: 'FAILED' });
      }
    }
    setSavingData(false);
  }

  async function updateCountry() {
    setSavingData(true);
    const userToken = localStorage.getItem('@alliancesystem/token');
    if (
      localData.name === undefined
      || localData.initials === undefined
      || localData.countryid === undefined) {
      dispatch({ type: 'CREATE_POPUP', message: 'Você não pode editar o nome e/ou identificador de uma cargo colocando o valor vazio.', popupType: 'FAILED' });
    } else {
      const response = await api.put(`/travels/country/${localData.id}`, { name: localData.name, initials: localData.initials, countryid: localData.countryid }, { headers: { Authorization: userToken } });
      if (!response.data.error) {
        dispatch({ type: 'RELOAD_DATA' });
        dispatch({ type: 'CREATE_POPUP', message: `Os dados do país ${localData.name} foram alterados com sucesso`, popupType: 'SUCCESS' });
      } else if (response.data.errorName === 'NOTHING_TO_UPDATE') {
        dispatch({ type: 'CREATE_POPUP', message: 'Você não está enviando uma alteração sem mudar nenhuma das informações.', popupType: 'FAILED' });
      } else {
        dispatch({ type: 'CREATE_POPUP', message: 'Ocorreu um erro desconhecido. Para mais informações, contacte um Desenvolvedor', popupType: 'FAILED' });
      }
    }
    setSavingData(false);
  }

  async function deleteCountry() {
    dispatch({ type: 'CREATE_POPUP', message: 'Por questão de segurança, essa função se encontra desabilitada. Para utilizá-la, procure pelo Setor de Desenvolvimento.', popupType: 'WARNING' });
  }

  if (travelSelector.type === 'CITIES') {
    if (travelSelector.isSidebarOpen) {
      if (travelSelector.isCreateTabOpen) {
        return (
          <>
            <StatusPopup isShowing={travelSelector.popup.isPopupOpen} text={travelSelector.popup.popupMessage} type={travelSelector.popup.popupType} height="30px" dispatchName="CLOSE_POPUP" />
            <button type="button" style={{ backgroundColor: '#673ab7' }} onClick={() => { registerNewCity(); }} disabled={savingData}>Salvar Nova Cidade</button>
            <div style={{ float: 'left', width: '100%' }}>
              <form style={{ marginTop: '20px', marginLeft: '20px' }}>
                <div className="field fieldSpace">
                  <label>Nome da Cidade:</label>
                  <input type="text" value={localData.name} onChange={(e) => { setLocalData({ ...localData, name: e.target.value }); }} />
                </div>
                <div className="field fieldSpace">
                  <label>País:</label>
                  <select name="countries" value={localData.countryid} onChange={(e) => { setLocalData({ ...localData, countryid: e.target.value }); }}>
                    {countries.map((data) => (
                      <option value={data.countryid}>{data.name}</option>
                    ))}
                  </select>
                </div>
                <div className="field fieldSpace">
                  <label>Identificador:</label>
                  <input type="text" value={localData.cityid} onChange={(e) => { setLocalData({ ...localData, cityid: e.target.value }); }} />
                </div>
              </form>
            </div>
          </>
        );
      }
      return (
        <>
          <StatusPopup isShowing={travelSelector.popup.isPopupOpen} text={travelSelector.popup.popupMessage} type={travelSelector.popup.popupType} height="30px" dispatchName="CLOSE_POPUP" />
          <button type="button" style={{ backgroundColor: '#673ab7' }} onClick={() => { updateCity(); }} disabled={savingData}>Editar Dados da Cidade</button>
          <button type="button" style={{ backgroundColor: '#673ab7' }} onClick={() => { deleteCity(); }}>Deletar Cidade</button>
          <button type="button" style={{ backgroundColor: '#673ab7' }} onClick={() => { createDataDispatch(); }}>Criar Nova Cidade</button>
          <div style={{ float: 'left', width: '100%' }}>
            <form style={{ marginTop: '20px', marginLeft: '20px' }}>
              <div className="field fieldSpace">
                <label>ID:</label>
                <input type="number" value={localData.id} onChange={(e) => { setLocalData({ ...localData, id: e.target.value }); }} disabled />
              </div>
              <div className="field fieldSpace">
                <label>Nome da Cidade:</label>
                <input type="text" value={localData.name} onChange={(e) => { setLocalData({ ...localData, name: e.target.value }); }} />
              </div>
              <div className="field fieldSpace">
                <label>País:</label>
                <select name="countries" value={localData.countryid} onChange={(e) => { setLocalData({ ...localData, countryid: e.target.value }); }}>
                  {countries.map((data) => (
                    <option value={data.countryid}>{data.name}</option>
                  ))}
                </select>
              </div>
              <div className="field fieldSpace">
                <label>Identificador:</label>
                <input type="text" value={localData.cityid} onChange={(e) => { setLocalData({ ...localData, cityid: e.target.value }); }} />
              </div>
            </form>
          </div>
        </>
      );
    }
    return (
      <button type="button" style={{ backgroundColor: '#673ab7' }} onClick={() => { createDataDispatch(); }}>Criar Nova Cidade</button>
    );
  }

  if (travelSelector.type === 'COMPANIES') {
    if (travelSelector.isSidebarOpen) {
      if (travelSelector.isCreateTabOpen) {
        return (
          <>
            <StatusPopup isShowing={travelSelector.popup.isPopupOpen} text={travelSelector.popup.popupMessage} type={travelSelector.popup.popupType} height="30px" dispatchName="CLOSE_POPUP" />
            <button type="button" style={{ backgroundColor: '#673ab7' }} onClick={() => { createCompany(); }} disabled={savingData}>Salvar Nova Empresa</button>
            <div style={{ float: 'left', width: '100%' }}>
              <form style={{ marginTop: '20px', marginLeft: '20px' }}>
                <div className="field fieldSpace">
                  <label>Nome da Empresa:</label>
                  <input type="text" value={localData.name} onChange={(e) => { setLocalData({ ...localData, name: e.target.value }); }} disabled={savingData} />
                </div>
                <div className="field fieldSpace">
                  <label>Identificador:</label>
                  <input type="text" value={localData.companyid} onChange={(e) => { setLocalData({ ...localData, companyid: e.target.value }); }} disabled={savingData} />
                </div>
              </form>
            </div>
          </>
        );
      }
      return (
        <>
          <StatusPopup isShowing={travelSelector.popup.isPopupOpen} text={travelSelector.popup.popupMessage} type={travelSelector.popup.popupType} height="30px" dispatchName="CLOSE_POPUP" />
          <button type="button" style={{ backgroundColor: '#673ab7' }} onClick={() => { updateCompany(); }} disabled={savingData}>Editar Dados da Empresa</button>
          <button type="button" style={{ backgroundColor: '#673ab7' }} onClick={() => { deleteCompany(); }}>Deletar Empresa</button>
          <button type="button" style={{ backgroundColor: '#673ab7' }} onClick={() => { createDataDispatch(); }}>Criar Nova Empresa</button>
          <div style={{ float: 'left', width: '100%' }}>
            <form style={{ marginTop: '20px', marginLeft: '20px' }}>
              <div className="field fieldSpace">
                <label>ID:</label>
                <input type="number" value={localData.id} onChange={(e) => { setLocalData({ ...localData, id: e.target.value }); }} disabled />
              </div>
              <div className="field fieldSpace">
                <label>Nome da Empresa:</label>
                <input type="text" value={localData.name} onChange={(e) => { setLocalData({ ...localData, name: e.target.value }); }} disabled={savingData} />
              </div>
              <div className="field fieldSpace">
                <label>Identificador:</label>
                <input type="text" value={localData.companyid} onChange={(e) => { setLocalData({ ...localData, companyid: e.target.value }); }} disabled={savingData} />
              </div>
            </form>
          </div>
        </>
      );
    }
    return (
      <button type="button" style={{ backgroundColor: '#673ab7' }} onClick={() => { createDataDispatch(); }}>Criar Nova Empresa</button>
    );
  }

  if (travelSelector.type === 'CARGOS') {
    if (travelSelector.isSidebarOpen) {
      if (travelSelector.isCreateTabOpen) {
        return (
          <>
            <StatusPopup isShowing={travelSelector.popup.isPopupOpen} text={travelSelector.popup.popupMessage} type={travelSelector.popup.popupType} height="30px" dispatchName="CLOSE_POPUP" />
            <button type="button" style={{ backgroundColor: '#673ab7' }} onClick={() => { createCargo(); }} disabled={savingData}>Salvar Nova Empresa</button>
            <div style={{ float: 'left', width: '100%' }}>
              <form style={{ marginTop: '20px', marginLeft: '20px' }}>
                <div className="field fieldSpace">
                  <label>Nome da Carga:</label>
                  <input type="text" value={localData.name} onChange={(e) => { setLocalData({ ...localData, name: e.target.value }); }} disabled={savingData} />
                </div>
                <div className="field fieldSpace">
                  <label>Identificador:</label>
                  <input type="text" value={localData.cargoid} onChange={(e) => { setLocalData({ ...localData, cargoid: e.target.value }); }} disabled={savingData} />
                </div>
              </form>
            </div>
          </>
        );
      }
      return (
        <>
          <StatusPopup isShowing={travelSelector.popup.isPopupOpen} text={travelSelector.popup.popupMessage} type={travelSelector.popup.popupType} height="30px" dispatchName="CLOSE_POPUP" />
          <button type="button" style={{ backgroundColor: '#673ab7' }} onClick={() => { updateCargo(); }} disabled={savingData}>Editar Dados da Carga</button>
          <button type="button" style={{ backgroundColor: '#673ab7' }} onClick={() => { deleteCargo(); }}>Deletar Carga</button>
          <button type="button" style={{ backgroundColor: '#673ab7' }} onClick={() => { createDataDispatch(); }}>Criar Nova Carga</button>
          <div style={{ float: 'left', width: '100%' }}>
            <form style={{ marginTop: '20px', marginLeft: '20px' }}>
              <div className="field fieldSpace">
                <label>ID:</label>
                <input type="number" value={localData.id} onChange={(e) => { setLocalData({ ...localData, id: e.target.value }); }} disabled />
              </div>
              <div className="field fieldSpace">
                <label>Nome da Carga:</label>
                <input type="text" value={localData.name} onChange={(e) => { setLocalData({ ...localData, name: e.target.value }); }} disabled={savingData} />
              </div>
              <div className="field fieldSpace">
                <label>Identificador:</label>
                <input type="text" value={localData.cargoid} onChange={(e) => { setLocalData({ ...localData, cargoid: e.target.value }); }} disabled={savingData} />
              </div>
            </form>
          </div>
        </>
      );
    }
    return (
      <button type="button" style={{ backgroundColor: '#673ab7' }} onClick={() => { createDataDispatch(); }}>Criar Nova Carga</button>
    );
  }

  if (travelSelector.type === 'COUNTRIES') {
    if (travelSelector.isSidebarOpen) {
      if (travelSelector.isCreateTabOpen) {
        return (
          <>
            <StatusPopup isShowing={travelSelector.popup.isPopupOpen} text={travelSelector.popup.popupMessage} type={travelSelector.popup.popupType} height="30px" dispatchName="CLOSE_POPUP" />
            <button type="button" style={{ backgroundColor: '#673ab7' }} onClick={() => { createCountry(); }} disabled={savingData}>Salvar Novo País</button>
            <div style={{ float: 'left', width: '100%' }}>
              <form style={{ marginTop: '20px', marginLeft: '20px' }}>
                <div className="field fieldSpace">
                  <label>Nome do País:</label>
                  <input type="text" value={localData.name} onChange={(e) => { setLocalData({ ...localData, name: e.target.value }); }} disabled={savingData} />
                </div>
                <div className="field fieldSpace">
                  <label>Sigla:</label>
                  <input type="text" value={localData.initials} onChange={(e) => { setLocalData({ ...localData, initials: e.target.value }); }} disabled={savingData} />
                </div>
                <div className="field fieldSpace">
                  <label>Identificador:</label>
                  <input type="text" value={localData.countryid} onChange={(e) => { setLocalData({ ...localData, countryid: e.target.value }); }} disabled={savingData} />
                </div>
              </form>
            </div>
          </>
        );
      }
      return (
        <>
          <StatusPopup isShowing={travelSelector.popup.isPopupOpen} text={travelSelector.popup.popupMessage} type={travelSelector.popup.popupType} height="30px" dispatchName="CLOSE_POPUP" />
          <button type="button" style={{ backgroundColor: '#673ab7' }} onClick={() => { updateCountry(); }} disabled={savingData}>Editar Dados do País</button>
          <button type="button" style={{ backgroundColor: '#673ab7' }} onClick={() => { deleteCountry(); }}>Deletar País</button>
          <button type="button" style={{ backgroundColor: '#673ab7' }} onClick={() => { createDataDispatch(); }}>Criar Novo País</button>
          <div style={{ float: 'left', width: '100%' }}>
            <form style={{ marginTop: '20px', marginLeft: '20px' }}>
              <div className="field fieldSpace">
                <label>ID:</label>
                <input type="number" value={localData.id} onChange={(e) => { setLocalData({ ...localData, id: e.target.value }); }} disabled />
              </div>
              <div className="field fieldSpace">
                <label>Nome do País:</label>
                <input type="text" value={localData.name} onChange={(e) => { setLocalData({ ...localData, name: e.target.value }); }} disabled={savingData} />
              </div>
              <div className="field fieldSpace">
                <label>Sigla:</label>
                <input type="text" value={localData.initials} onChange={(e) => { setLocalData({ ...localData, initials: e.target.value }); }} disabled={savingData} />
              </div>
              <div className="field fieldSpace">
                <label>Identificador:</label>
                <input type="text" value={localData.countryid} onChange={(e) => { setLocalData({ ...localData, countryid: e.target.value }); }} disabled={savingData} />
              </div>
            </form>
          </div>
        </>
      );
    }
    return (
      <button type="button" style={{ backgroundColor: '#673ab7' }} onClick={() => { createDataDispatch(); }}>Criar Novo País</button>
    );
  }

  return (null);
}
