import React, { useEffect, useState } from 'react';

import api from '../../../services/MainAPI';

import '../css/listDriversInterface.css';

export default function ListDriversInferface() {
  const [driversData, setDriversData] = useState([]);

  async function getDriversData() {
    const userToken = localStorage.getItem('@alliancesystem/token');

    const res = await api.get('/gap/listdrivers', {}, { headers: { Authorization: userToken } });
    setDriversData(res.data);
  }

  useEffect(() => {
    getDriversData();
  }, []);

  return (
    <main className="defaultMargin listdrivers">
      <div className="upperarea" style={{ width: '50%' }}>
        <li className="name" style={{ listStyleType: 'none' }}>Lista de Motoristas</li>
      </div>
      <table>
        <tr className="titles">
          <th>ID</th>
          <th>Nome Real</th>
          <th>Apelido</th>
          <th>TruckersMPID</th>
          <th>Cargo</th>
          <th>&nbsp;</th>
        </tr>
        {driversData.map((data) => (
          <tr>
            <td>{data.member_id}</td>
            <td>{data.real_name}</td>
            <td>{data.nickname}</td>
            <td>{data.truckersmp_id}</td>
            <td>{data.role_name}</td>
            <td><a href={`/alliancesystem/gap/driver/${data.member_id}`}>Mais Detalhes</a></td>
          </tr>
        ))}
      </table>
    </main>
  );
}
