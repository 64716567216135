import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import api from '../../../services/MainAPI';

import '../css/listDriverInterface.css';

export default function ListFiredDriverInterface() {
  const { id } = useParams();

  const [data, setData] = useState({});

  async function getDriverData() {
    const userToken = localStorage.getItem('@alliancesystem/token');
    const res = await api.get(`/gap/fireddriver/${id}`, {}, { headers: { Authorization: userToken } });
    setData(res.data);
  }

  useEffect(() => {
    getDriverData();
  }, []);

  return (
    <main className="defaultMargin listdriver">
      <h1 className="title">Dados do Ex-Motorista:</h1>
      <ul className="maindata">
        <li>
          <span className="bold">Nome Real:</span>
          {' '}
          {data.realname}
        </li>
        <li>
          <span className="bold">Apelido:</span>
          {' '}
          {data.nickname}
        </li>
        <li>
          <span className="bold">Apelido no TruckersMP:</span>
          {' '}
          {data.tmpnickname}
        </li>
        <li>
          <span className="bold">VTC no TruckersMP:</span>
          {' '}
          {data.vtc}
        </li>
        <li>
          <span className="bold">ID no Discord:</span>
          {' '}
          {data.discord}
        </li>
        <li>
          <span className="bold">Data de Admissão:</span>
          {' '}
          {data.admissiondate}
        </li>
        <li>
          <span className="bold">Data de Demissão:</span>
          {' '}
          {data.resignDate}
        </li>
        <li>
          <span className="bold">Perfil no TruckersMP:</span>
          {' '}
          <a href={`https://truckersmp.com/user/${data.truckersmpid}`} rel="noreferrer" target="_blank">Clique Aqui</a>
        </li>
        <li>
          <span className="bold">Perfil na Steam:</span>
          {' '}
          <a href={`https://steamcommunity.com/profiles/${data.steamID}`} rel="noreferrer" target="_blank">Clique Aqui</a>
        </li>
        <li>
          <span className="bold">Demitido por:</span>
          {' '}
          {data.resignBy}
        </li>
        <li>
          <span className="bold">Banido Permanentemente:</span>
          {' '}
          {data.isBannedText}
        </li>
        <br />
        <li>
          <span className="bold">Razão da Demissão: </span>
          <br />
          <textarea className="observation" value={data.resignObs} disabled />
        </li>
        <br />
        <li>
          <span className="bold">Observações GAP: </span>
          <br />
          <textarea className="observation" value={data.recursosHumanosObs} disabled />
        </li>
      </ul>
    </main>
  );
}
