/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable max-len */
import React from 'react';

import '../style/apply2.css';

function Apply2() {
  return (
    <div style={{ backgroundColor: '#d8d8d8', marginTop: '-5px' }}>
      <div className="middle-2-apply">
        <div className="middle-2-1-apply">
          <h3>Requisitos da Alliance</h3>
          <p>Para ser um motorista da Alliance, você precisa seguir os requisitos mínimos abaixo:</p>
          <ul>
            <li>Você precisa ter uma versão original do ETS2</li>
            <li>Você precisa ter no mínimo 2 horas de jogo</li>
            <li>Você precisa conhecer as regras do TruckersMP</li>
            <li>Você precisa possuir o Discord para a comunicação</li>
            <li>Você não pode ter mais de 3 bans ativos.</li>
          </ul>
          <p>Caso você cumpre todos os requisitos, entre no nosso servidor do Discord e procure algum motorista que ele irá lhe auxiliar de como entrar na Alliance.</p>
          <p className="discord"><a target="_blank" href="https://discord.gg/Zzq7Shs">Entre no Nosso Discord</a></p>
        </div>
        <div className="middle-2-2-apply">
          <h3>Mais Informações:</h3>
          <p>Caso não conheça o Discord, veja o vídeo abaixo para aprender a como entrar na ferramenta:</p>
          <iframe width="100%" title="Alliance Discord Video" height="220px" src="https://www.youtube.com/embed/Ba9x24DTkmw" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
        </div>
      </div>
    </div>
  );
}

export default Apply2;
