import React from 'react';
import Helmet from 'react-helmet';
import { Provider } from 'react-redux';

import store from '../../store';
import ManageDataNameTab from './components/ManageDataNameTab';

export default function ManageDataName() {
  return (
    <Provider store={store}>
      <Helmet>
        <title>Gerenciar Banco de Nomes - AllianceSystem</title>
      </Helmet>
      <ManageDataNameTab />
    </Provider>
  );
}
