import React from 'react';

function StaffMember(props) {
  const { profileImage, name, role } = props;

  return (
    <div className="staff-profile">
      <img alt="Profile" src={`https://alliancevirtual.com.br:3002/files/profile/${profileImage}`} />
      <h2>{name}</h2>
      <h3>{role}</h3>
    </div>
  );
}

export default StaffMember;
