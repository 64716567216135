import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import api from '../../../services/MainAPI';

export default function DataNameTabLeft() {
  const travelSelector = useSelector((state) => state.manageTravel);
  const dispatch = useDispatch();

  const [cityData, setCityData] = useState([]);
  const [companyData, setCompanyData] = useState([]);
  const [cargoData, setCargoData] = useState([]);
  const [countryData, setCountryData] = useState([]);

  async function updateData() {
    const userToken = localStorage.getItem('@alliancesystem/token');
    if (travelSelector.type === 'CITIES') {
      const response = await api.get('/travels/city', {}, { headers: { Authorization: userToken } });
      setCityData(response.data.citiesdata);
    } else if (travelSelector.type === 'COMPANIES') {
      const response = await api.get('/travels/company', {}, { headers: { Authorization: userToken } });
      setCompanyData(response.data.companiesdata);
    } else if (travelSelector.type === 'CARGOS') {
      const response = await api.get('/travels/cargo', {}, { headers: { Authorization: userToken } });
      setCargoData(response.data.cargodata);
    } else if (travelSelector.type === 'COUNTRIES') {
      const response = await api.get('/travels/country', {}, { headers: { Authorization: userToken } });
      setCountryData(response.data.countriesdata);
    }
  }

  function dispatchEditData(data) {
    dispatch({ type: 'EDIT_SIDEBAR_DATA', data });
  }

  useEffect(() => {
    updateData();
  }, [travelSelector.type]);

  useEffect(() => {
    if (travelSelector.reloadData) {
      updateData();
      dispatch({ type: 'CANCEL_RELOAD' });
    }
  }, [travelSelector.reloadData]);

  if (travelSelector.type === 'CITIES') {
    return (
      <div>
        <table className="datanametable">
          <tr id="tableheader">
            <th>ID</th>
            <th>Nome</th>
            <th>País</th>
            <th>Identificador</th>
            <th> </th>
          </tr>
          {
            cityData.map((_data) => (
              <tr>
                <td className="dataname">{_data.id}</td>
                <td className="dataname">{_data.name}</td>
                <td className="dataname">{_data.country}</td>
                <td className="dataname">{_data.cityid}</td>
                <td className="dataname" style={{ width: '100px' }}><a href="#" onClick={() => { dispatchEditData(_data); }}>Editar Cidade</a></td>
              </tr>
            ))
          }

        </table>
      </div>
    );
  }
  if (travelSelector.type === 'COMPANIES') {
    return (
      <div>
        <table className="datanametable">
          <tr id="tableheader">
            <th>ID</th>
            <th>Nome</th>
            <th>Identificador</th>
            <th> </th>
          </tr>
          {
            companyData.map((_data) => (
              <tr>
                <td className="dataname">{_data.id}</td>
                <td className="dataname">{_data.name}</td>
                <td className="dataname">{_data.companyid}</td>
                <td className="dataname" style={{ width: '120px' }}><a href="#" onClick={() => { dispatchEditData(_data); }}>Editar Empresa</a></td>
              </tr>
            ))
          }

        </table>
      </div>
    );
  }
  if (travelSelector.type === 'CARGOS') {
    return (
      <div>
        <table className="datanametable">
          <tr id="tableheader">
            <th>ID</th>
            <th>Nome</th>
            <th>Identificador</th>
            <th> </th>
          </tr>
          {
            cargoData.map((_data) => (
              <tr>
                <td className="dataname">{_data.id}</td>
                <td className="dataname">{_data.name}</td>
                <td className="dataname">{_data.cargoid}</td>
                <td className="dataname" style={{ width: '90px' }}><a href="#" onClick={() => { dispatchEditData(_data); }}>Editar Carga</a></td>
              </tr>
            ))
          }

        </table>
      </div>
    );
  }
  if (travelSelector.type === 'COUNTRIES') {
    return (
      <div>
        <table className="datanametable">
          <tr id="tableheader">
            <th>ID</th>
            <th>Nome</th>
            <th>Sigla</th>
            <th>Identificador</th>
            <th> </th>
          </tr>
          {
            countryData.map((_data) => (
              <tr>
                <td className="dataname">{_data.id}</td>
                <td className="dataname">{_data.name}</td>
                <td className="dataname">{_data.initials}</td>
                <td className="dataname">{_data.countryid}</td>
                <td className="dataname" style={{ width: '80px' }}><a href="#" onClick={() => { dispatchEditData(_data); }}>Editar País</a></td>
              </tr>
            ))
          }

        </table>
      </div>
    );
  }
  return null;
}
