import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { Slide } from '@mui/material';
import Header from './components/Header';
import Apply1 from './components/Apply1';
import Apply2 from './components/Apply2';
import Apply3 from './components/Apply3';
import Footer from './components/Footer';
import LoadingScreen from './LoadingScreen';

function Apply() {
  const state = {
    activeTab: {
      home: '',
      aboutUs: '',
      events: '',
      staff: '',
      apply: 'active',
    },
    linkWebsite: '/apply',
  };

  const [isLoading, setIsLoading] = React.useState(true);

  useEffect(() => {
    const onPageLoad = () => {
      setTimeout(() => { setIsLoading(false); }, 1000);
    };

    while (document.readyState === 'loading') {
      setTimeout(() => { }, 100);
    }

    onPageLoad();
  }, []);

  if (isLoading) {
    return (
      <Slide direction="up" in={isLoading} enter={false} unmountOnExit>
        <div>
          <LoadingScreen />
        </div>
      </Slide>
    );
  }

  return (
    <>
      <Helmet>
        <title>Aplique-se - Alliance Virtual</title>
      </Helmet>
      <Header activeTab={state.activeTab} linkWebsite={state.linkWebsite} />
      <main>
        <Apply1 />
        <Apply2 />
        <Apply3 />
      </main>
      <Footer />
    </>
  );
}

export default Apply;
