/* eslint-disable react/jsx-no-script-url */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable max-len */
/* eslint-disable no-empty */
/* eslint-disable no-else-return */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import '../css/registerNotification.css';

import gifCheck from '../images/check.gif';
import gifError from '../images/error.gif';

export default function RegisterNotification() {
  const notification = useSelector((state) => state.registerUser);
  const dispatch = useDispatch();

  function closePopUp() {
    dispatch({ type: 'CLOSE_POPUP' });
  }

  if (notification.active) {
    if (notification.error) {
      if (notification.errorName === 'INVALID_PERMISSION') {
        return (

          <div className="registerNotification">
            <div className="containner">
              <a href="javascript:void(0)" onClick={closePopUp}><i className="fas fa-times" /></a>
              <img src={gifError} alt="Check Gif" />
              <h1>Ocorreu um Erro!</h1>
              <p>Você não possui permissão nessa área do sistema! Entre em contato com o Setor de Desenvolvimento para mais informações.</p>
            </div>
          </div>
        );
      } else if (notification.errorName === 'USER_ALREADY_IN_THE_SYSTEM') {
        return (
          <div className="registerNotification">
            <div className="containner">
              <a href="javascript:void(0)" onClick={closePopUp}><i className="fas fa-times" /></a>
              <img src={gifError} alt="Check Gif" />
              <h1>O Motorista já está cadastrado no Sistema!</h1>
              <p>O motorista já se encontra no banco de dados da ALLIANCE. Verifique se você digitou o TruckersMP ID corretamente.</p>
            </div>
          </div>
        );
      } else if (notification.errorName === 'USER_BANNED_IN_THE_SYSTEM') {
        return (
          <div className="registerNotification">
            <div className="containner">
              <a href="javascript:void(0)" onClick={closePopUp}><i className="fas fa-times" /></a>
              <img src={gifError} alt="Check Gif" />
              <h1>O Motorista se encontra banido da ALLIANCE!</h1>
              <p>O motorista se encontra banido da ALLIANCE no banco de dados.</p>
            </div>
          </div>
        );
      }
    } else {
      return (
        <div className="registerNotification">
          <div className="containner">
            <a href="javascript:void(0)" onClick={closePopUp}><i className="fas fa-times" /></a>
            <img src={gifCheck} alt="Check Gif" />
            <h1>Motorista Cadastrado com Sucesso!</h1>
            <p>
              O motorista
              {' '}
              {notification.data.nickname}
              {' '}
              foi cadastrado com sucesso!
            </p>
            <p>
              Nome Real:
              {' '}
              {notification.data.realname}
              <br />
              Apelido:
              {' '}
              {notification.data.nickname}
              <br />
              Apelido no TruckersMP:
              {' '}
              {notification.data.truckersmpnickname}
              <br />
            </p>
            <p className="mb-0">
              O código de verificação do discord é:
              {' '}
              <span style={{ fontWeight: 'bold' }}>{notification.data.discordcode}</span>
              .
              {' '}
              <br />
              O motorista deve executar o comando:
              {' '}
              <span style={{ fontWeight: 'bold' }}>
                /validarconta
                {' '}
                {notification.data.discordcode}
              </span>

            </p>
          </div>
        </div>
      );
    }
  } else {
    return (
      null
    );
  }
}
