/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import api from '../../../services/MainAPI';

import '../css/datanametab.css';

import Header from '../../components/UI_Components/Header';
import LateralNavigation from '../../components/UI_Components/LateralNavigation';
import DataNameTabLeft from './DataNameTabLeft';
import SidebarDataName from './SidebarDataName';

let checkLogin = false;

const dropdownOptions = [
  { name: 'Selecione uma opção', value: 'SELECT_AN_OPTION', isDisabled: true },
  { name: 'Cidades', value: 'CITIES', isDisabled: false },
  { name: 'Empresas', value: 'COMPANIES', isDisabled: false },
  { name: 'Cargas', value: 'CARGOS', isDisabled: false },
  { name: 'Países', value: 'COUNTRIES', isDisabled: false },
];

export default function ManageDataNameTab() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [primaryAuth, setPrimaryAuth] = useState([]);

  const [dropdownOption, setDropdownOption] = useState(dropdownOptions[0].value);

  async function checkLoginFunction() {
    if (!checkLogin) {
      const userToken = localStorage.getItem('@alliancesystem/token');
      const res = await api.get('/auth/primaryauth', {}, { headers: { Authorization: userToken } });
      if (res.data.error) {
        navigate('/alliancesystem/login');
      } else {
        setPrimaryAuth(res.data);
        checkLogin = true;
      }
    }
  }

  function updateDataType() {
    dispatch({ type: 'UPDATE_DATATYPE', datatype: dropdownOption });
  }

  useEffect(() => {
    updateDataType();
  }, [dropdownOption]);

  checkLoginFunction();

  return (
    <>
      <Header primaryAuthData={primaryAuth} sectorColor="developmentHeader" />
      <LateralNavigation primaryAuthData={primaryAuth} navigationSector="TRAVELS" defaultTab={{ value: true, name: 'Voltar às Viagens', url: '/travels' }} />
      <main className="defaultMargin manageDataName" id="travelsAdmin">
        <div className="travelsLeft">
          <h1 style={{ marginLeft: '10px' }}>Gerenciamento do Banco de Nomes</h1>
          <div className="field">
            <label style={{ marginLeft: '10px' }}>Escolher Banco de Nomes:</label>
            <select name="divisions" value={dropdownOption} onChange={(e) => setDropdownOption(e.target.value)}>
              {dropdownOptions.map((value) => (
                <option value={value.value} disabled={value.isDisabled}>{value.name}</option>
              ))}
            </select>
          </div>
          <DataNameTabLeft />
        </div>
        <div className="travelsRight">
          <div className="travelButtons">
            <SidebarDataName />
          </div>
        </div>
      </main>
    </>
  );
}
